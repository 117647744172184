import { inject, Injectable } from '@angular/core'
import { IAutomationFormData } from '@employer/app/components/jobs-automation/automation-form.model'
import { AutomationTransform, IAutomationDTO } from '@employer/app/components/jobs-automation/automation.dto'
import { environment } from '@employer/environments/environment'
import { isNotNil } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Store } from '@ngrx/store'
import { filter, firstValueFrom, map, Observable, tap } from 'rxjs'
import { getCurrentToken } from 'shared-lib'

export type ISetAutomationReq = { jobPostContentId: string; formData: IAutomationFormData }

@Injectable({ providedIn: 'root' })
export class JobPostAutomationService {
  #logger = inject(E11Logger)
  #store = inject(Store)
  #restClient = new RestApiClient({
    baseUrl: environment.services.jobsAutomation,
    token: this.#store.select(getCurrentToken).pipe(filter(isNotNil)),
  })

  constructor() {}

  getAutomationForJobPost(jobPostContentId: string): Observable<IAutomationFormData | undefined> {
    this.#logger.log('getAutomationForJobPost ', jobPostContentId)
    return this.#restClient.get<IAutomationDTO>(`jobs-automation/${jobPostContentId}`).pipe(
      tap(a => this.#logger.log('getAutomationForJobPost: ', a)),
      map(res => (res.data ? AutomationTransform.dtoToForm(res.data) : undefined))
    )
  }

  async setAutomation(req: ISetAutomationReq) {
    this.#logger.log('JobPostAutomationService.setAutomation', req)
    const dto = AutomationTransform.formToDto(req.formData)
    const res = await firstValueFrom(this.#restClient.put(`jobs-automation/${req.jobPostContentId}`, dto).pipe(map(r => r.data)))
    this.#logger.log('JobPostAutomationService.setAutomation res', res)

    return req.formData
  }
}
