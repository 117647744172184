import { Component, OnInit } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'

@Component({
  selector: 'automation-reminder-info',
  standalone: true,
  imports: [TranslateModule, E11TooltipModule],
  template: `
    <div class="e11-my-4 e11-flex e11-gap-1 e11-items-center ">
      <span>{{ 'Sent as a reminder if the candidate does not respond in the chosen time.' | translate }}</span>
      <e11-tooltip
        [color]="'dark'"
        tooltipText="{{
          'A response to any message here will stop this message from being sent. This is only a reminder that previous requests have not been addressed.'
            | translate
        }}"
      >
        <span class="material-icons-outlined md-18">help_outline</span>
      </e11-tooltip>
    </div>
  `,
})
export class AutomationReminderInfoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
