<div class="e11-flex e11-border-b e11-border-skin-app-borders">
  <div *ngIf="question" class="e11-grow e11-w-2/3">
    <!-- Line item -->

    <div class="e11-block md:e11-flex e11-py-4">
      <div class="e11-grow">
        <p class="e11-mb-0 e11-line-clamp-2 e11-pr-0 md:e11-pr-4">{{ question.question | translate }}</p>
      </div>
      <div class="e11-flex e11-justify-between md:e11-justify-end">
        <div class="e11-flex e11-items-center e11-pr-0 md:e11-pr-2">
          <e11-chip
            class="e11-mr-4 e11-mb-0"
            [value]="question.authorName"
            [containerClassOverrides]="'!e11-mb-0'"
            [contentClassOverrides]="'e11-whitespace-nowrap'"
          ></e11-chip>
        </div>
        <!-- Author -->
        <div class="e11-flex e11-items-center e11-pr-0 md:e11-pr-4">
          <span class="material-icons-outlined e11-mb-0 e11-mr-1 md-14">
            {{ question.inputType === QuestionType.Video ? 'videocam' : 'article' }}
          </span>
          <span class="e11-text-xs e11-mr-1 e11-hidden md:e11-flex">{{
            question.inputType === QuestionType.Video ? 'Video' : ('Text' | translate)
          }}</span>
          <span class="e11-whitespace-nowrap e11-text-xs e11-mr-2">{{ question.__updatedAt | date : 'MMM dd yyyy ' }}</span>
        </div>

        <!-- Control(s) -->
        <div class="e11-flex e11-items-center e11-pr-0">
          <span
            class="material-icons-outlined e11-text-skin-primary-accent hover:e11-text-skin-primary e11-cursor-pointer md-18 md:md-24"
            (click)="editClicked.emit(question)"
          >
            edit
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
