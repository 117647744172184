import { Injectable } from '@angular/core'
import { IAutomationFormData } from '@employer/app/components/jobs-automation/automation-form.model'
import { CollectionRepository } from '@engineering11/web-api-firebase'
import { COLLECTIONS } from 'shared-lib'
import { IAutomatedQuestionMessageFormData } from './auto-question-message.model'

export interface ICompanyAutomationFormData extends IAutomationFormData {
  id?: string
  automatedQuestionMessage?: IAutomatedQuestionMessageFormData
}

@Injectable({ providedIn: 'root' })
export class JobPostAutomationCompanyRepository extends CollectionRepository<ICompanyAutomationFormData> {
  protected COLLECTION = COLLECTIONS.CUSTOMER_AUTOMATION_CONFIG
}
