import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FormatCategoriesPipe } from '@employer/app/pipes/format-categories.pipe'
import { RegistrationModule } from '@engineering11/registration-web'
import { WebUtilityModule } from '@engineering11/web-utilities'
import { LetDirective } from '@ngrx/component'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { SharedModule } from '../_shared/shared.module'
import { InterviewQuestionCategoriesComponent } from './components/interview-question-categories/interview-question-categories.component'
import { InterviewQuestionFormComponent } from './components/interview-question-form/interview-question-form.component'
import { InterviewQuestionLibraryComponent } from './components/interview-question-library.component'
import { InterviewQuestionsListComponent } from './components/interview-question-list/interview-question-list.component'
import { InterviewQuestionSkeletonComponent } from './components/interview-question-skeleton/interview-question-skeleton.component'
import { InterviewQuestionTagsComponent } from './components/interview-question-tags/interview-question-tags.component'
import { InterviewQuestionComponent } from './components/interview-question/interview-question.component'

@NgModule({
  declarations: [
    InterviewQuestionComponent,
    InterviewQuestionCategoriesComponent,
    InterviewQuestionTagsComponent,
    InterviewQuestionFormComponent,
    InterviewQuestionLibraryComponent,
    InterviewQuestionsListComponent,
    InterviewQuestionSkeletonComponent,

    // Pipes
    FormatCategoriesPipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    UiComponentLibraryModule,
    FormsModule,
    ReactiveFormsModule,
    RegistrationModule,
    WebUtilityModule,
    LetDirective,
  ],
  exports: [],
  providers: [],
})
export class InterviewQuestionLibraryModule {}
