import { Component, Input, ViewChild } from '@angular/core'
import { E11TabComponent, E11TabsModule } from '@engineering11/ui-lib/e11-tabs'
import { TranslateModule } from '@ngx-translate/core'
import { IQuestionUpdate, QuestionType } from 'shared-lib'
import { AutomatedQuestionSearchInterviewComponent } from './automated-question-search-interview.component'
import { AutomatedQuestionWriteInterviewComponent } from './automated-question-write-interview.component'
import { AutomatedQuestionSelectedInterviewComponent } from './automated-question-selected-interview.component'
import { IAutoQuestionFormData } from '../../auto-questions.model'
import { AddInterviewQuestionRequest, IInterviewQuestion } from '@employer/app/modules/interview-question-library/interview-question.model'
import { uuid } from '@engineering11/utility'
import { LetDirective } from '@ngrx/component'
import { InterviewQuestionStore } from '@employer/app/store/interview-question.store'

@Component({
  selector: 'automated-question-interview-form',
  standalone: true,
  imports: [
    E11TabsModule,
    TranslateModule,
    AutomatedQuestionSearchInterviewComponent,
    AutomatedQuestionWriteInterviewComponent,
    AutomatedQuestionSelectedInterviewComponent,
    LetDirective,
  ],
  template: `
    <div class="e11-block e11-my-4"></div>
    <ng-container>
      <e11-tabs [tabsClassOverrides]="'e11-justify-start e11-gap-6'">
        <e11-tab [title]="'Questions' + getQuestionNumbers() | translate" #selectedQuestions>
          <automated-question-selected-interview
            (onEditQuestion)="setQuestionIdToEdit($event)"
            (onDeleteQuestion)="deleteQuestion($event)"
            [isValidationErrors]="isValidationErrors"
            [questions]="questions"
          ></automated-question-selected-interview>
        </e11-tab>
        <e11-tab [title]="'Search existing questions' | translate" #searchQuestions>
          <automated-question-search-interview (onSaveQuestions)="saveQuestions($event)"></automated-question-search-interview>
        </e11-tab>
        <e11-tab [title]="(questionToEdit ? 'Edit question' : 'Write a new question') | translate" #writeQuestions>
          <automated-question-write-interview
            [questionToEdit]="questionToEdit"
            (onAddQuestion)="addQuestion($event)"
            (onUpdateQuestion)="editQuestion($event)"
            (onSaveToLibrary)="saveToLibrary($event)"
            (onEditCancelled)="cancelEdit()"
          ></automated-question-write-interview>
        </e11-tab>
      </e11-tabs>
    </ng-container>
  `,
  styles: [],
})
export class AutomatedQuestionInterviewFormComponent {
  @ViewChild('writeQuestions') writeQuestions!: E11TabComponent
  @ViewChild('searchQuestions') searchQuestions!: E11TabComponent
  @ViewChild('selectedQuestions') selectedQuestions!: E11TabComponent

  isValidationErrors = false

  @Input() set formData(data: IAutoQuestionFormData | undefined) {
    if (data) {
      this.questions = data.questions
    }
  }

  questions: IInterviewQuestion[] = []
  questionToEdit: IInterviewQuestion | undefined

  constructor(private interviewQuestionStore: InterviewQuestionStore) {}

  trackBy(_index: number, item: any) {
    return item.id
  }

  setQuestionIdToEdit(questionId: string) {
    this.questionToEdit = this.questions.find(question => question.id === questionId)
    this.toggleTab('write')
  }

  editQuestion(question: IQuestionUpdate) {
    this.questions = this.questions.map(q =>
      q.id === question.id ? { ...q, question: question.question || q.question, inputType: question.type || q.inputType } : q
    )
    this.toggleTab('selected')
    this.questionToEdit = undefined
  }

  cancelEdit() {
    this.questionToEdit = undefined
    this.toggleTab('selected')
  }

  deleteQuestion(questionId: string) {
    this.questions = this.questions.filter(q => q.id !== questionId)
  }

  getQuestionNumbers() {
    return ` (${this.questions.length})`
  }

  addQuestion(event: AddInterviewQuestionRequest) {
    const newQuestion: IInterviewQuestion = {
      ...event,
      id: event.id || uuid(),
    }

    this.questions = [...this.questions, newQuestion]
    this.toggleTab('selected')
  }

  saveQuestions(questions: IInterviewQuestion[] | IInterviewQuestion) {
    const newQuestions = Array.isArray(questions) ? questions : [questions]
    this.questions = [...this.questions, ...newQuestions]
    this.toggleTab('selected')
  }

  saveToLibrary(question: AddInterviewQuestionRequest) {
    this.interviewQuestionStore.onAddQuestion(question)
  }

  validateAndReturnData(): Partial<IAutoQuestionFormData> | undefined {
    if (this.questions.length === 0) {
      this.isValidationErrors = true
      return undefined
    }
    return { questions: this.questions }
  }

  private toggleTab(tab: 'selected' | 'search' | 'write') {
    this.selectedQuestions.active = tab === 'selected'
    this.searchQuestions.active = tab === 'search'
    this.writeQuestions.active = tab === 'write'
  }
}
