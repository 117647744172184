import {
  autoQuestionAutomationDefaults,
  autoQuestionFollowUpDefaults,
  autoQuestionInitialMessageDefaults,
  autoQuestionWarningDefaults,
  IAutoQuestionFormData,
} from '@employer/app/components/jobs-automation/automation-auto-question/auto-questions.model'
import { Duration } from '@engineering11/date-time'
import { Optional } from '@engineering11/types'
import {
  IRequestMoreFormData,
  IRequestUpdatesFormData,
  requestedUpdateDefaults,
  requestedUpdateFollowUpDefaults,
  requestedUpdateInitialMessageDefaults,
  requestedUpdateWarningDefaults,
} from './auto-request-updates.model'
import { autoResponseDefaults } from './auto-response.model'
import { IRejectCandidateFormData, rejectCandidateDefaults } from './automation-candidate-rejection/candidate-rejection.model'
import { IAutomationFormData } from './automation-form.model'
import { IUnresponsiveCandidateFormData, unresponsiveCandidateDefaults } from './automation-unresponsive-candidate/unresponsive-candidate.model'
import { IDelayFormData, ISendMessageFormData } from './job-automation.model'

export interface ISendMessageDto extends Omit<ISendMessageFormData, keyof IDelayFormData> {
  delay?: Duration
}
export interface IUnresponsiveCandidateDto extends Omit<IUnresponsiveCandidateFormData, keyof IDelayFormData> {
  delay?: Duration
}
export interface IRejectCandidateDto extends Omit<IRejectCandidateFormData, keyof IDelayFormData> {
  delay?: Duration
}
export interface IRequestMoreDto {
  requestedUpdate: IRequestUpdatesFormData
  initialMessage: ISendMessageDto
  followUp: ISendMessageDto
  warning: ISendMessageDto
}

export interface IAutoQuestionDto extends Omit<IAutoQuestionFormData, 'initialMessage' | 'followUp' | 'warning'> {
  initialMessage: ISendMessageDto
  followUp: ISendMessageDto
  warning: ISendMessageDto
}

export interface IAutomationDTO {
  autoResponse: ISendMessageDto
  requestMore: IRequestMoreDto
  autoQuestions: { enabled: boolean; autoQuestions: IAutoQuestionDto[] }
  unresponsiveCandidate: IUnresponsiveCandidateDto
  rejectCandidate: IRejectCandidateDto
}

export class AutomationTransform {
  static formToDto(formData: IAutomationFormData): IAutomationDTO {
    return {
      autoResponse: AutomationTransform.sendMessageFormToDto(formData.autoResponse),
      requestMore: AutomationTransform.requestMoreFormToDto(formData.requestMore),
      autoQuestions: {
        enabled: formData.autoQuestions.enabled,
        autoQuestions: AutomationTransform.autoQuestionFormToDto(formData.autoQuestions.autoQuestions),
      },
      unresponsiveCandidate: AutomationTransform.unresponsiveCandidateFormToDto(formData.unresponsiveCandidate ?? unresponsiveCandidateDefaults),
      rejectCandidate: AutomationTransform.rejectCandidateFormToDto(formData.rejectCandidate ?? rejectCandidateDefaults),
    }
  }

  static dtoToForm(dto?: IAutomationDTO): IAutomationFormData {
    return {
      autoResponse: dto ? { ...autoResponseDefaults, ...AutomationTransform.sendMessageDtoToForm(dto?.autoResponse) } : autoResponseDefaults,
      requestMore: AutomationTransform.requestToDtoToForm(dto?.requestMore),
      autoQuestions: dto?.autoQuestions
        ? {
            enabled: dto.autoQuestions.enabled,
            autoQuestions: AutomationTransform.autoQuestionDtoToForm(dto.autoQuestions.autoQuestions),
          }
        : autoQuestionAutomationDefaults,
      unresponsiveCandidate: dto?.unresponsiveCandidate
        ? { ...unresponsiveCandidateDefaults, ...AutomationTransform.unresponsiveCandidateDtoToForm(dto?.unresponsiveCandidate) }
        : unresponsiveCandidateDefaults,
      rejectCandidate: dto?.rejectCandidate
        ? { ...rejectCandidateDefaults, ...AutomationTransform.rejectCandidateDtoToForm(dto?.rejectCandidate) }
        : rejectCandidateDefaults,
    }
  }

  private static requestMoreFormToDto(formData: IRequestMoreFormData): IRequestMoreDto {
    const initialMessage = AutomationTransform.sendMessageFormToDto(formData.initialMessage)
    const followUp = AutomationTransform.sendMessageFormToDto(formData.followUp)
    const warning = AutomationTransform.sendMessageFormToDto(formData.warning)

    return { initialMessage, followUp, warning, requestedUpdate: formData.requestedUpdate }
  }

  private static autoQuestionFormToDto(formData: IAutoQuestionFormData[]): IAutoQuestionDto[] {
    return formData.map(question => ({
      ...question,
      initialMessage: AutomationTransform.sendMessageFormToDto(question.initialMessage),
      followUp: AutomationTransform.sendMessageFormToDto(question.followUp),
      warning: AutomationTransform.sendMessageFormToDto(question.warning),
    }))
  }

  private static autoQuestionDtoToForm(dto: IAutoQuestionDto[]): IAutoQuestionFormData[] {
    return dto.map(question => {
      const initialMessage = AutomationTransform.sendMessageDtoToForm(question.initialMessage)
      const followUp = AutomationTransform.sendMessageDtoToForm(question.followUp)
      const warning = AutomationTransform.sendMessageDtoToForm(question.warning)
      return {
        ...question,
        initialMessage: { ...autoQuestionInitialMessageDefaults, ...initialMessage },
        followUp: { ...autoQuestionFollowUpDefaults, ...followUp },
        warning: { ...autoQuestionWarningDefaults, ...warning },
      }
    })
  }
  private static unresponsiveCandidateFormToDto(formMessage: IUnresponsiveCandidateFormData): IUnresponsiveCandidateDto {
    return { ...formMessage, delay: delayToDuration(formMessage) }
  }
  private static unresponsiveCandidateDtoToForm(
    dto: IUnresponsiveCandidateDto
  ): Optional<IUnresponsiveCandidateFormData, 'delayDuration' | 'delayUnits'> {
    return { ...dto, ...durationToDelay(dto.delay) }
  }
  private static rejectCandidateFormToDto(formMessage: IRejectCandidateFormData): IRejectCandidateDto {
    return { ...formMessage, delay: delayToDuration(formMessage) }
  }
  private static rejectCandidateDtoToForm(dto: IRejectCandidateDto): Optional<IRejectCandidateFormData, 'delayDuration' | 'delayUnits'> {
    return { ...dto, ...durationToDelay(dto.delay) }
  }
  private static requestToDtoToForm(dto?: IRequestMoreDto): IRequestMoreFormData {
    const initialMessage = dto
      ? { ...requestedUpdateInitialMessageDefaults, ...AutomationTransform.sendMessageDtoToForm(dto.initialMessage) }
      : requestedUpdateInitialMessageDefaults
    const followUp = dto
      ? { ...requestedUpdateFollowUpDefaults, ...AutomationTransform.sendMessageDtoToForm(dto.followUp) }
      : requestedUpdateFollowUpDefaults
    const warning = dto
      ? { ...requestedUpdateWarningDefaults, ...AutomationTransform.sendMessageDtoToForm(dto.warning) }
      : requestedUpdateWarningDefaults
    const requestedUpdate = { ...requestedUpdateDefaults, ...dto?.requestedUpdate }
    return { initialMessage, followUp, warning, requestedUpdate }
  }

  private static sendMessageFormToDto(formMessage: ISendMessageFormData): ISendMessageDto {
    return { ...formMessage, delay: delayToDuration(formMessage) }
  }

  private static sendMessageDtoToForm(dto: ISendMessageDto): Optional<ISendMessageFormData, 'delayDuration' | 'delayUnits'> {
    return { ...dto, ...durationToDelay(dto.delay) }
  }
}

function delayToDuration(data: IDelayFormData): Duration {
  const duration: Duration = {}
  duration[data.delayUnits] = data.delayDuration
  return duration
}

function durationToDelay(duration?: Duration): IDelayFormData | undefined {
  if (!duration) return undefined
  const keyValue = Object.entries(duration).find(([k, v]) => v)
  if (!keyValue) return undefined
  return { delayUnits: keyValue[0] as keyof Duration, delayDuration: keyValue[1] }
}
