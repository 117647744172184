import { Component, TemplateRef, ViewChild } from '@angular/core'
import { E11ModalGlobalService } from '@engineering11/ui-lib/e11-modal'
import { TranslateModule } from '@ngx-translate/core'
import { CommonModule } from '@angular/common'
import { E11CardModule } from '@engineering11/ui-lib/e11-card'
import { WHAT_HAPPENS, WHAT_TO_DO } from './automated-question.constants'

@Component({
  selector: 'automated-question-popup-helper-modal',
  standalone: true,
  imports: [TranslateModule, CommonModule, E11CardModule],
  template: `
    <ng-container>
      <ng-template #content>
        <div class="e11-flex e11-flex-col e11-gap-6">
          <div class="e11-flex e11-flex-col e11-gap-2">
            <div class="e11-flex e11-flex-row e11-gap-2 e11-text-skin-primary-accent">
              <span class="material-icons">auto_mode</span>
              <h3>
                {{ 'Automatically engage candidates with questions' | translate }}
              </h3>
            </div>
            <p>
              {{
                'Automate the process of asking your candidates important and relevant questions about the job they are applying for. These questions will be sent to the candidate after the conditions you configure are met.'
                  | translate
              }}
            </p>
          </div>
          <div class="e11-flex e11-flex-col e11-gap-4">
            <h3 class="e11-text-">
              {{ 'All you need to do' | translate }}
            </h3>
            <div class="e11-grid e11-grid-cols-1 md:e11-grid-cols-3 e11-gap-4">
              <e11-card containerClass="e11-p-4 e11-border e11-rounded-lg e11-flex e11-flex-col e11-gap-2" *ngFor="let item of WHAT_TO_DO">
                <div class="e11-flex e11-gap-2 e11-items-center">
                  <span class="material-icons-outlined e11-text-skin-primary-accent">{{ item.icon }}</span>
                  <h4 class="e11-capitalize e11-font-semibold">{{ item.title | translate }}</h4>
                </div>
                <p>
                  {{ item.description | translate }}
                </p>
              </e11-card>
            </div>
            <h3 class="e11-text-">
              {{ 'What happens' | translate }}
            </h3>
            <div class="e11-grid e11-grid-cols-1 md:e11-grid-cols-3 e11-gap-4">
              <e11-card containerClass="e11-p-4 e11-border e11-rounded-lg e11-flex e11-flex-col e11-gap-2" *ngFor="let item of WHAT_HAPPENS">
                <div class="e11-flex e11-gap-2 e11-items-center">
                  <span class="material-icons-outlined e11-text-skin-primary-accent">{{ item.icon }}</span>
                  <h4 class="e11-capitalize e11-font-semibold">{{ item.title | translate }}</h4>
                </div>
                <p>
                  {{ item.description | translate }}
                </p>
              </e11-card>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  `,
  styles: ``,
})
export class AutomatedQuestionPopupHelperModalComponent {
  @ViewChild('content') content!: TemplateRef<any>

  WHAT_TO_DO = WHAT_TO_DO
  WHAT_HAPPENS = WHAT_HAPPENS

  constructor(private modalService: E11ModalGlobalService) {}

  open() {
    this.modalService.openModal({
      body: this.content,
      title: 'Automation Configuration - Candidate Questions',
      width: 'xl',
      bodyHeight: 'large',
    })
  }
}
