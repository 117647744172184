import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ReactiveFormsModule, Validators } from '@angular/forms'
import { delayUnitSelectOptions } from '@employer/app/components/jobs-automation/job-automation.model'
import { AutomationDelayInfoComponent } from '@employer/app/components/jobs-automation/partials/automation-delay-info.component'
import {
  E11DynamicFormBuilderComponent,
  E11DynamicFormModule,
  E11FormForType,
  e11FieldInputNumber,
  e11FieldInputSelect,
  e11FieldInputSwitch,
  e11FormFor,
  e11FormLayoutFullWidth,
  e11FormLayoutInlineGrid,
  e11LayoutGroup,
} from '@engineering11/ui-lib/e11-form-builder'
import { E11InputLabelModule } from '@engineering11/ui-lib/e11-input-label'
import { E11SwitchModule } from '@engineering11/ui-lib/e11-switch'
import { TranslateModule } from '@ngx-translate/core'

import { AutomationPopupHelperModalComponent } from '../partials/automation-popup-helper-modal.component'
import { ALL_YOU_NEED, HEADER_INFO, WHAT_HAPPENS } from './automated-rejection.constants'
import { IRejectCandidateFormData, rejectionReasonSelectOptions, rejectCandidateDefaults } from './candidate-rejection.model'

type RejectCandidate = E11FormForType<IRejectCandidateFormData>

@Component({
  selector: 'job-automation-candidate-rejection-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    E11DynamicFormModule,
    E11InputLabelModule,
    E11SwitchModule,
    AutomationDelayInfoComponent,
    AutomationPopupHelperModalComponent,
  ],
  template: `
    <div class="e11-flex e11-gap-2 e11-items-center">
      <h3 class="e11-text-skin-primary-accent e11-mb-2">{{ 'Candidate Rejection ' | translate }}</h3>
      <automation-popup-helper-modal
        [title]="'Automation Configuration - Reject Candidate'"
        [allYouNeed]="ALL_YOU_NEED"
        [whatHappens]="WHAT_HAPPENS"
        [headerInfo]="HEADER_INFO"
      />
      <e11-switch
        [checked]="rejectCandidateForm.form.get('enabled')?.value ?? false"
        (changes)="rejectCandidateForm.form?.get('enabled')?.setValue($event)"
        [size]="'xs'"
      ></e11-switch>
    </div>
    <div class="md:e11-flex e11-gap-2">
      <div class="e11-flex-1">
        <p>
          {{
            'This will automatically change a candidates status to “Unresponsive” if they do not respond to requests or messages. A notification will inform them that they are no longer considered an active candidate for the job they applied for.'
              | translate
          }}
        </p>
      </div>
      <div class="e11-flex-1">
        <e11-dynamic-form-builder
          #rejectCandidateForm
          [loading]="loading"
          [fieldGroups]="form"
          [initialValue]="formData"
          [hideSubmitMenu]="true"
          (valueChanges)="onValueChanges($event)"
        >
          <ng-template e11Template="delayTimeHeaderTemplate">
            <automation-delay-info label="When are they rejected?" toolTipText="This is delayed from the time of application submission.">
            </automation-delay-info>
          </ng-template>
        </e11-dynamic-form-builder>
      </div>
    </div>
  `,
})
export class AutomationCandidateRejectionFormComponent implements OnInit {
  @ViewChild('rejectCandidateForm') rejectCandidateForm!: E11DynamicFormBuilderComponent<IRejectCandidateFormData>
  @Input() loading: boolean = false
  @Input() formData?: IRejectCandidateFormData = rejectCandidateDefaults
  @Output() valueChanges = new EventEmitter<IRejectCandidateFormData>()
  form!: RejectCandidate
  ALL_YOU_NEED = ALL_YOU_NEED
  WHAT_HAPPENS = WHAT_HAPPENS
  HEADER_INFO = HEADER_INFO

  ngOnInit(): void {
    this.form = this.buildRejectCandidateForm()
  }
  onValueChanges(value: IRejectCandidateFormData) {
    this.valueChanges.emit(value)
  }

  private buildRejectCandidateForm(): E11FormForType<IRejectCandidateFormData> {
    const { enabled, delayDuration, delayUnits, rejectionReason } = rejectCandidateDefaults

    return e11FormFor<IRejectCandidateFormData>()([
      e11FormLayoutFullWidth(
        [
          e11FieldInputSwitch('enabled', 'Enabled', enabled, {
            validatorOrOpts: [],
          }),
        ],
        { containerClass: 'e11-hidden' }
      ),
      e11LayoutGroup([
        e11LayoutGroup([
          e11FormLayoutInlineGrid(
            [
              e11FieldInputNumber('delayDuration', 'Time frame:', delayDuration, {
                validatorOrOpts: [Validators.required, Validators.min(0)],
                hideInputError: true,
              }),
              e11FieldInputSelect('delayUnits', 'Unit', delayUnitSelectOptions, delayUnits, {
                validatorOrOpts: [Validators.required],
                hideInputError: true,
              }),
              e11FieldInputSelect('rejectionReason', 'Rejection Reason:', rejectionReasonSelectOptions, rejectionReason, {
                validatorOrOpts: [Validators.required],
                hideInputError: true,
              }),
            ],
            {
              fieldsClass: 'e11-w-1/2',
              containerClass: 'xl:e11-w-3/4 e11-mb-4',
              templateSpec: [
                {
                  templateKey: 'delayTimeHeaderTemplate',
                  templatePosition: 'top',
                },
              ],
            }
          ),
        ]),
      ]),
    ])
  }
}
