import { Component, Input, ViewChild } from '@angular/core'
import { E11InputModule } from '@engineering11/ui-lib/e11-input'
import {
  E11DynamicFormBuilderComponent,
  E11DynamicFormModule,
  e11FieldInputDynamicComponent,
  e11FieldInputText,
  e11FormFor,
  E11FormForType,
  e11FormLayoutFullWidth,
  e11FormLayoutInline3Grid,
} from '@engineering11/ui-lib/e11-form-builder'
import { Validators } from '@angular/forms'
import { AutomatedQuestionSwimlanes, IAutoQuestionFormComponent, IAutoQuestionFormData } from '../../auto-questions.model'
import { AutomatedQuestionTriggerSwimlanesComponent } from './automated-question-trigger-swimlanes.component'
import { NgClass, NgIf } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { E11InputErrorsModule } from '@engineering11/ui-lib/e11-input-errors'

export type AutomatedQuestionTriggerForm = Pick<IAutoQuestionFormData, 'name' | 'swimlane'>

@Component({
  selector: 'automated-question-trigger',
  standalone: true,
  imports: [E11InputModule, E11DynamicFormModule, NgIf, TranslateModule, E11InputErrorsModule, NgClass],
  template: `
    <e11-dynamic-form-builder *ngIf="triggerFormFieldGroup" [hideSubmitMenu]="true" #triggerForm [fieldGroups]="triggerFormFieldGroup">
      <ng-template e11Template="nameHeader">
        <p class="e11-font-semibold">{{ 'Name this set of questions' | translate }}</p>
      </ng-template>
      <ng-template e11Template="swimlaneHeader">
        <div class="e11-mb-4">
          <p
            class="e11-font-semibold e11-mb-0"
            [ngClass]="{ 'e11-text-skin-error': triggerForm.form.get('swimlane')?.invalid && triggerForm.form.get('swimlane')?.touched }"
          >
            {{ 'Pick a trigger' | translate }}
          </p>
          <e11-input-errors *ngIf="triggerForm.form.get('swimlane')" [formControl]="triggerForm.form.get('swimlane')!"></e11-input-errors>
        </div>
      </ng-template>
    </e11-dynamic-form-builder>
  `,
  styles: ``,
})
export class AutomatedQuestionTriggerFormComponent implements IAutoQuestionFormComponent {
  @ViewChild('triggerForm') triggerForm!: E11DynamicFormBuilderComponent<AutomatedQuestionTriggerForm>

  @Input() set formData(formData: IAutoQuestionFormData | undefined) {
    this.triggerFormFieldGroup = this.buildForm(formData)
  }

  triggerFormFieldGroup: E11FormForType<AutomatedQuestionTriggerForm> | undefined = undefined

  get f() {
    return this.triggerForm?.form?.controls
  }

  private buildForm(formData: IAutoQuestionFormData | undefined): E11FormForType<AutomatedQuestionTriggerForm> {
    return e11FormFor<AutomatedQuestionTriggerForm>()([
      e11FormLayoutInline3Grid(
        [
          e11FieldInputText('name', 'Name', formData?.name, {
            validatorOrOpts: [Validators.required],
          }),
        ],
        {
          templateSpec: { templatePosition: 'top', templateKey: 'nameHeader' },
        }
      ),
      e11FormLayoutFullWidth(
        [
          e11FieldInputDynamicComponent<'swimlane', AutomatedQuestionSwimlanes | undefined, AutomatedQuestionTriggerSwimlanesComponent>(
            'swimlane',
            '',
            AutomatedQuestionTriggerSwimlanesComponent,
            formData?.swimlane,
            {
              validatorOrOpts: [Validators.required],
            }
          ),
        ],
        {
          templateSpec: { templatePosition: 'top', templateKey: 'swimlaneHeader' },
        }
      ),
    ])
  }

  validateAndReturnData(): AutomatedQuestionTriggerForm | undefined {
    this.triggerForm.onSubmitClick()
    if (this.triggerForm.form.invalid) {
      return undefined
    }
    return this.triggerForm.form.value
  }
}
