import { ICandidateUser, IEmployerUser } from '@cnect/user-shared'
import { IFileReceipt } from '@engineering11/files-web'
import { APPLICATION_STATE, DegreeLevel, MatchType, ReferenceType, SkillLevel } from './enum'

export interface IEducation {
  id: string
  schoolName: string
  degreeLevel: DegreeLevel
  degreeTitle?: string
  degreeType?: string
  startDate?: Date | null
  endDate?: Date | null
  currentlyAttending?: boolean // Is this strictly needed? Could this be indicated with endDate = undefined?
  notCompleted?: boolean
}

export interface IAddress {
  address?: string
  city?: string
  state?: string
  zip?: string
}

export interface IFirstImpression {
  id: string
  video: IFileReceipt
  thumb: IFileReceipt
  name?: string
  description?: string
  script?: string
  default?: boolean
  videoLength?: string
  views?: number
}

export interface ISettingsFirstImpression {
  tutorialsEnabled: boolean
  videoCount: number
}

export interface ISettingsTutorialModals {
  introModalEnabled?: boolean
  applicationTutorialModalEnabled?: boolean
}

export interface ISettingsVirtualDialogue {
  tutorialsEnabled: boolean
}

export interface ISkill {
  name: string
  level: SkillLevel
}

export type SkillsAndCerts = { skills: ISkill[]; certifications: ICertification[] }

export interface ICertification {
  name: string
}

export interface Matched {
  matchType: MatchType
}

export interface Requirable {
  required: boolean
}

export interface Selectable {
  selected: boolean
}

export interface IMatchedSkill extends ISkill, Matched {}
export interface IMatchedCertification extends ICertification, Matched {}

// Representation of what the job posting asks for in terms of skills
export interface IRequirableSkill extends ISkill, Requirable {}
export interface IRequirableCertification extends ICertification, Requirable {}

// Representation of what the candidate has compared to what the posting is asking for
export interface IMatchedRequirableSkill extends ISkill, Matched, Requirable {}
export interface IMatchedRequirableCertification extends ICertification, Matched, Requirable {}

export interface IWorkHistory {
  id: string
  companyName: string
  jobTitle?: string
  jobDescription?: string
  currentlyWorking?: boolean
  startDate?: Date | null
  endDate?: Date | null
}

export interface IReference {
  default: boolean
  email?: string
  firstName: string
  id: string
  lastName: string
  phone?: string
  referenceRelationship?: string
  referenceType: ReferenceType
  companyName?: string
}

export type ISelectableWorkHistory = IWorkHistory & Partial<Selectable>
export type ISelectableEducation = IEducation & Partial<Selectable>
export type ISelectableReference = IReference & Partial<Selectable>

export type ICnectUser = IEmployerUser | ICandidateUser
export interface IUserPreferences {
  emailEnabled: boolean
}
/** For employer users to configure which swimlanes they watch by default */
export type SwimlaneWatchConfig = Record<APPLICATION_STATE, boolean>
