import { Pipe, PipeTransform } from '@angular/core'
import { IAddress } from '../model/interfaces'

@Pipe({ name: 'addressFormat' })
export class AddressFormatPipe implements PipeTransform {
  transform(address?: IAddress): string | undefined {
    if (!address) return address
    const city = address.city && address.state ? `${address.city}, ` : address.city ?? ''

    return `${city}${address.state ?? ''} ${address.zip ?? ''}`
  }
}
