import { Component, inject, TemplateRef, ViewChild } from '@angular/core'
import { E11ModalGlobalService } from '@engineering11/ui-lib/e11-modal'
import { TranslateModule } from '@ngx-translate/core'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'

@Component({
  selector: 'automation-upgrade-prompt',
  standalone: true,
  imports: [TranslateModule, E11TooltipModule],
  template: `
    <div class="e11-text-skin-primary-accent e11-flex e11-mb-4 e11-items-center e11-cursor-pointer" (click)="upgradeClicked()">
      <span class="material-icons e11-text-[18px]"> military_tech</span>
      {{ 'UPGRADE and be able to customize your messages.' | translate }}
    </div>

    <ng-template #upgradeModalBody>
      <p>
        {{
          'Upgrade to the premium version of this feature to enable customization of your Requests for More messages, including the ability to send videos and attachments. Contact a Cnect Customer Success representative to learn more.'
            | translate
        }}
      </p>
    </ng-template>
  `,
})
export class AutomationUpgradePromptComponent {
  @ViewChild('upgradeModalBody') upgradeModalBody!: TemplateRef<any>

  #modalGlobalService = inject(E11ModalGlobalService)

  constructor() {}

  upgradeClicked() {
    this.#modalGlobalService.openModal({ title: 'Contact Us', body: this.upgradeModalBody, width: 'md' })
  }
}
