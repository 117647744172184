import { Component, Input, OnInit } from '@angular/core'
import { NgClass } from '@angular/common'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'
import { TranslateModule } from '@ngx-translate/core'
import {
  autoQuestionFollowUpDefaults,
  autoQuestionInitialMessageDefaults,
  autoQuestionWarningDefaults,
  IAutoQuestionFormData,
} from '@employer/app/components/jobs-automation/automation-auto-question/auto-questions.model'

type AutoQuestionMessageType = Pick<IAutoQuestionFormData, 'initialMessage' | 'followUp' | 'warning'>

@Component({
  selector: 'auto-question-message-indicator',
  standalone: true,
  imports: [NgClass, E11TooltipModule, TranslateModule],
  template: `
    <e11-tooltip
      [color]="'dark'"
      [tooltipText]="
        enabled ? typeToText[this.type] + ' ' + enabledMessage : (typeToText[this.type] + ' message is currently not active.' | translate)
      "
    >
      <span class="material-icons-outlined" [ngClass]="{ 'e11-text-skin-success': enabled, 'e11-text-skin-grey/70': !enabled }">
        {{ enabled ? messageStateIndicator : 'chat_bubble_outline' }}
      </span>
    </e11-tooltip>
  `,
  styles: ``,
})
export class AutoQuestionMessageIndicatorComponent implements OnInit {
  messageStateIndicator!: string
  enabledMessage!: string
  @Input({ required: true }) type!: keyof AutoQuestionMessageType
  @Input({ required: true }) enabled!: boolean
  @Input({ required: true }) message!: string

  typeToText: Record<keyof AutoQuestionMessageType, string> = {
    initialMessage: 'Initial',
    followUp: 'Follow-up',
    warning: 'Warning',
  }

  typeToDefaultText: Record<keyof AutoQuestionMessageType, string> = {
    initialMessage: autoQuestionInitialMessageDefaults.message,
    followUp: autoQuestionFollowUpDefaults.message,
    warning: autoQuestionWarningDefaults.message,
  }

  ngOnInit() {
    this.messageStateIndicator = this.message !== this.typeToDefaultText[this.type] ? 'mark_unread_chat_alt' : 'mark_chat_unread'
    this.enabledMessage =
      this.message !== this.typeToDefaultText[this.type] ? 'custom message is currently active.' : 'system message is currently active.'
  }
}
