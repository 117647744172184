import { Injectable } from '@angular/core'
import {
  autoQuestionFormDefaults,
  FormStep,
  IAutoQuestionAutomationFormData,
  IAutoQuestionFormData,
} from '@employer/app/components/jobs-automation/automation-auto-question/auto-questions.model'
import { isNotNil, uuid } from '@engineering11/utility'
import { ComponentStore } from '@ngrx/component-store'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { combineLatestWith, filter, map } from 'rxjs/operators'
import { getCurrentUserId } from 'shared-lib'
import { AutomatedQuestionTriggerForm } from './partials/automated-question-trigger/automated-question-trigger-form.component'

export interface IAutoQuestionStoreState extends EntityState<IAutoQuestionFormData> {
  currentAutoQuestionFormId: string | undefined
  enabled: boolean
}

const autoQuestionAdapter = createEntityAdapter<IAutoQuestionFormData>({
  selectId: autoQuestion => autoQuestion.id,
})

const selectors = autoQuestionAdapter.getSelectors()

const initialState: IAutoQuestionStoreState = autoQuestionAdapter.getInitialState({
  currentAutoQuestionFormId: undefined,
  enabled: false,
})

export type AutoQuestionCreatePayload = Omit<Partial<IAutoQuestionFormData>, 'id'> & AutomatedQuestionTriggerForm

@Injectable({ providedIn: 'root' })
export class AutoQuestionStore extends ComponentStore<IAutoQuestionStoreState> {
  constructor(private store: Store) {
    super(initialState)
  }

  // SELECTORS
  readonly getState = this.select(s => s)
  readonly formData$: Observable<IAutoQuestionFormData | undefined> = this.select(s =>
    s.currentAutoQuestionFormId ? selectors.selectEntities(s)[s.currentAutoQuestionFormId] : undefined
  )
  readonly allAutoQuestionFormData$ = this.select(s => selectors.selectAll(s))
  readonly autoQuestionsEnabled$ = this.select(s => s.enabled)
  readonly completedSteps$: Observable<Record<FormStep, boolean>> = this.select(checkStepsCompletion)

  // EFFECTS
  onAddAutoQuestion = this.effect((formData$: Observable<AutoQuestionCreatePayload>) =>
    formData$.pipe(
      combineLatestWith(this.store.pipe(getCurrentUserId, filter(isNotNil))),
      map(([formData, ownerId]) => {
        const id = uuid()
        const payload: IAutoQuestionFormData = {
          ...autoQuestionFormDefaults,
          ...formData,
          id,
          ownerId,
        }
        this.addAutoQuestion(payload)
        this.setCurrentAutoQuestionFormId(id)
      })
    )
  )

  onUpdateCurrentAutoQuestion = this.effect((formData$: Observable<Partial<IAutoQuestionFormData>>) =>
    formData$.pipe(
      map(formData => {
        const currentAutoQuestionFormId = this.get().currentAutoQuestionFormId
        if (!currentAutoQuestionFormId) return []
        return this.updateAutoQuestion({ ...formData, id: currentAutoQuestionFormId })
      })
    )
  )

  onDeleteAutoQuestion = this.effect((id$: Observable<string>) =>
    id$.pipe(
      map(id => {
        this.removeAutoQuestion(id)
        this.setCurrentAutoQuestionFormId(undefined)
      })
    )
  )

  // UPDATERS

  public readonly toggleAutoQuestionsEnabled = this.updater((s, enabled: boolean) => ({ ...s, enabled }))
  public readonly setCurrentAutoQuestionFormId = this.updater((s, currentAutoQuestionFormId: string | undefined) => ({
    ...s,
    currentAutoQuestionFormId,
  }))

  public readonly setAutoQuestions = this.updater((s, formData: IAutoQuestionAutomationFormData) =>
    autoQuestionAdapter.setAll(formData.autoQuestions, {
      ...s,
      enabled: formData.enabled,
    })
  )

  public readonly resetAutoQuestions = this.updater(s =>
    autoQuestionAdapter.removeAll({
      ...s,
      enabled: false,
    })
  )

  public readonly updateAutoQuestion = this.updater(
    (
      s,
      formData: Partial<IAutoQuestionFormData> & {
        id: string
      }
    ) => autoQuestionAdapter.updateOne({ id: formData.id, changes: formData }, s)
  )
  private readonly addAutoQuestion = this.updater((s, formData: IAutoQuestionFormData) => autoQuestionAdapter.addOne(formData, s))

  private readonly removeAutoQuestion = this.updater((s, id: string) => autoQuestionAdapter.removeOne(id, s))
}

function checkStepsCompletion(s: IAutoQuestionStoreState): Record<FormStep, boolean> {
  const currentAutomation = s.currentAutoQuestionFormId ? selectors.selectEntities(s)[s.currentAutoQuestionFormId] : undefined
  if (!currentAutomation) {
    return {
      Trigger: false,
      Question: false,
      Messages: false,
    }
  }

  return {
    Trigger: !!(currentAutomation.name.length && currentAutomation.swimlane),
    Question: !!currentAutomation.questions.length,
    Messages: currentAutomation.initialMessage.enabled || currentAutomation.followUp.enabled || currentAutomation.warning.enabled,
  }
}
