import {
  autoQuestionFollowUpDefaults,
  autoQuestionInitialMessageDefaults,
  autoQuestionWarningDefaults,
} from '@employer/app/components/jobs-automation/automation-auto-question/auto-questions.model'
import { ISendMessageFormData } from '@employer/app/components/jobs-automation/job-automation.model'

export interface IAutomatedQuestionMessageFormData {
  initialMessage: ISendMessageFormData
  followUp: ISendMessageFormData
  final: ISendMessageFormData
}

export const automatedQuestionMessageFormDefaults: IAutomatedQuestionMessageFormData = {
  initialMessage: autoQuestionInitialMessageDefaults,
  followUp: autoQuestionFollowUpDefaults,
  final: autoQuestionWarningDefaults,
}
