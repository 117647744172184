// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ApiGatewayPathBuilder, CloudRunPathBuilder, Env, IEmployerEnvironment } from 'shared-lib'

const env: Env = 'ci'
const projectNumber = '524673897104'
const pathBuilder = new ApiGatewayPathBuilder(env)
const cloudRunPathBuilder = new CloudRunPathBuilder(projectNumber)

export const environment: IEmployerEnvironment = {
  production: false,
  env,
  firebaseConfig: {
    apiKey: 'AIzaSyBJnAEalpY0inF_NHTc8vC2Gk5GFNHdqx4',
    authDomain: 'ci-employer.cnected.com',
    databaseURL: 'https://cnect-ci.firebaseio.com',
    projectId: 'cnect-ci',
    storageBucket: 'cnect-ci.appspot.com',
    messagingSenderId: '524673897104',
    appId: '1:524673897104:web:d7d6f8312271d7ba47fe15',
    measurementId: 'G-TBMJN6NER9',
  },
  services: {
    cloudFunctions: 'https://us-central1-cnect-ci.cloudfunctions.net',
    applicationHistory: pathBuilder.build('applicationHistory'), // 'https://ci-api.cnected.com/application-history-api-gateway',
    content: pathBuilder.build('content'), // 'https://ci-api.cnected.com/content-api-gateway',
    employer: pathBuilder.build('employer'), // 'https://ci-api.cnected.com/employer-api-gateway',
    events: pathBuilder.build('events'), // 'https://ci-api.cnected.com/event-api-gateway',
    jobs: pathBuilder.build('jobs'), //'https://ci-api.cnected.com/jobs-api-gateway',
    registration: pathBuilder.build('registration'), // 'https://ci-api.cnected.com/registration-api-gateway',
    user: pathBuilder.build('user'), // 'https://ci-api.cnected.com/user-api-gateway',
    auth: pathBuilder.build('auth'), // 'https://ci-api.cnected.com/auth-api-gateway',
    files: pathBuilder.build('files'), // 'https://ci-api.cnected.com/files-api-gateway',
    messaging: pathBuilder.build('messaging'), // 'https://ci-api.cnected.com/messaging-api-gateway',
    virtualDialogue: pathBuilder.build('virtualDialogue'), // 'https://ci-api.cnected.com/virtual-dialogue-api-gateway',
    access: pathBuilder.build('access'), // 'https://ci-api.cnected.com/access-api-gateway',
    jobsIntegration: pathBuilder.build('jobsIntegration'), // 'https://ci-api.cnected.com/jobs-integration-api-gateway',
    notifications: pathBuilder.build('notifications'), // 'https://ci-api.cnected.com/notifications-api-gateway',
    customer: pathBuilder.build('customer'), // 'https://ci-api.cnected.com/customer-api-gateway',
    jobsAutomation: pathBuilder.build('jobsAutomation'),
    candidatePool: pathBuilder.build('candidatePool'),
    sse: cloudRunPathBuilder.build('sse'),
  },
  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: true, // Look to the auth guard for use of this value
  themesEnabled: true,
  v2ReCaptcha: '6LcQh4scAAAAAGFHzKO0pMDwtTDA9cAquJaGJ8L8',
  tenantId: 'B2B-USER-6i8yt',
  algoliaAppId: 'W2R5M3E540',
  cdnCname: 'ci-content.cnected.com',
  basePlatformHost: 'ci-employer.cnected.com',
  candidateExternalJobUrl: 'https://ci-network.cnected.com/#/landing/job/',
  candidateEmailActionRoute: 'https://ci-network.cnected.com/#/auth/actions',
  careersPageBaseLink: 'https://ci-careers.cnected.com/',
  employerBaseUrl: 'https://ci-employer.cnected.com/',
  employerEmailSuccessRoute: 'https://ci-employer.cnected.com/#/auth/action-success/verifyEmail',
  passwordRecoveryEmailTemplateId: 'sfIqysHX4k910Rq0K4r3',
  emailVerificationEmailTemplateId: '5KYG0zYnsdtR0DLtgjwv',
  betaMessageId: '1HV5MRdznzPO0ivgBbom',
  version: '035da6f',

  tenant: {
    appConfigBaseURL: 'https://ci-content.cnected.com/assets/bootstrap',
    defaultTenant: 'ci-employer.cnected.com',
  },
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
