import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { IAutomationForms } from '@employer/app/components/jobs-automation/job-automation-form.component'
import { ISendMessageFormData } from '@employer/app/components/jobs-automation/job-automation.model'
import { JobsPostAutomationCompanySettingsStore } from '@employer/app/modules/customer/views/jobs-post-automation-company-settings/jobs-post-automation-company-settings.store'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'
import { deepCopy } from '@engineering11/utility'
import { LetDirective } from '@ngrx/component'
import { TranslateModule } from '@ngx-translate/core'
import { IRequestUpdatesFormData } from '../auto-request-updates.model'
import { IAutomationFormData } from '../automation-form.model'

type AutomationFormData = ISendMessageFormData | IRequestUpdatesFormData

@Component({
  selector: 'automation-reset',
  standalone: true,
  imports: [TranslateModule, E11TooltipModule, LetDirective, CommonModule],
  template: `
    <ng-container *ngrxLet="companyAutomationFormData$ as formData">
      <div class="e11-flex e11-gap-4 e11-text-sm e11-mb-4">
        <e11-tooltip
          [color]="'dark'"
          [tooltipText]="'This will reset the message content to what has been configured globally under your Company Settings.' | translate"
        >
          <button
            *ngIf="!loading && formData"
            (click)="onResetFormData(formData)"
            type="button"
            class="e11-text-skin-primary-accent e11-flex e11-items-center"
          >
            <span class="material-icons-outlined md-18">replay</span>
            Reset
          </button>
        </e11-tooltip>
      </div>
    </ng-container>
  `,
  styles: ``,
})
export class AutomationResetComponent {
  @Input({ required: true }) formField!: keyof IAutomationForms
  @Input() loading = true

  @Output() onFormFieldReset = new EventEmitter<AutomationFormData>()

  companyAutomationFormData$ = this.jobsPostAutomationCompanySettingsStore.formData$

  constructor(private jobsPostAutomationCompanySettingsStore: JobsPostAutomationCompanySettingsStore) {}

  onResetFormData(companyData: IAutomationFormData) {
    const companyFormData = deepCopy(companyData)
    if (!companyFormData) return
    switch (this.formField) {
      case 'autoResponse':
        this.onFormFieldReset.emit(companyFormData.autoResponse)
        break
      case 'requestMoreInitial':
        this.onFormFieldReset.emit(companyFormData.requestMore.initialMessage)
        break
      case 'requestMoreFollowUp':
        this.onFormFieldReset.emit(companyFormData.requestMore.followUp)
        break
      case 'requestMoreWarning':
        this.onFormFieldReset.emit(companyFormData.requestMore.warning)
        break
      case 'requestedUpdate':
        this.onFormFieldReset.emit(companyFormData.requestMore.requestedUpdate)
        break
    }
  }
}
