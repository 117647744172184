import { Injectable } from '@angular/core'
import { JobPostApplicationRepository } from '@employer/app/repositories/job-post-application.repository'
import { IRequestedUpdate } from 'shared-lib'
import { arrayUnion } from '@engineering11/web-api-firebase'

@Injectable({ providedIn: 'root' })
export class JobPostApplicationService {
  constructor(public repository: JobPostApplicationRepository) {}

  addRequestedUpdate(jobPostId: string, applicationId: string, requestedUpdate: IRequestedUpdate[]) {
    return this.repository.update({ id: applicationId, jobPostId, requestedUpdated: arrayUnion(...requestedUpdate) })
  }

  addConversationId(jobPostId: string, applicationId: string, conversationId: string) {
    return this.repository.update({ id: applicationId, jobPostId, conversationId })
  }
}
