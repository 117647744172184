import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { Permission } from '@employer/app/config/permission.config'
import { ManageCompanyLogoComponent } from '@employer/app/modules/customer/views/manage-company-logo/manage-company-logo.component'
import { ManageEmployerSettingsComponent } from '@employer/app/modules/customer/views/manage-employer-settings/manage-employer.component'
import { PermissionsGuard } from '@engineering11/access-web'
import { FeatureGuard } from '@engineering11/config-web'
import { CnectFeature } from 'shared-lib'
import { FileNotFoundComponent } from '../_404/404.component'
import { InterviewQuestionsListComponent } from '../interview-question-library/components/interview-question-list/interview-question-list.component'
import { CustomerDepartmentsComponent } from './views/departments.component'
import { CustomerHomeComponent } from './views/home/customer-home.component'
import { CustomerLocationsComponent } from './views/locations.component'
import { CustomerSocialMediaComponent } from './views/social-media/social-media.component'
import { CareersPageHeroComponent } from './views/careers-page-hero/careers-page-hero.component'
import { NavItemNames } from '@employer/app/services/navigation.service'
import { CustomerComplianceComponent } from './views/compliance.component'
import { employerGuard } from './employer.guard'
import { JobsPostAutomationCompanySettingsComponent } from './views/jobs-post-automation-company-settings/jobs-post-automation-company-settings.component'

const employerInterviewQuestions: CnectFeature = 'employerInterviewQuestions'

// Title Information
// In the router the 'title' is for browser's title shown in the browser tab
// The 'data title' is used by the view to update the title service for the page's header

const routes: Routes = [
  {
    path: '',
    canActivate: [PermissionsGuard, employerGuard],
    data: { permission: Permission.COMPANY_MANAGE },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: CustomerHomeComponent,
        data: { title: 'Company Account Settings' },
      },
      {
        path: 'home',
        component: CustomerHomeComponent,
        data: { title: 'Company Account Settings' },
      },
      {
        path: 'logo',
        component: ManageCompanyLogoComponent,
        data: { title: 'Company Logo and Video' },
      },
      {
        path: 'content',
        component: ManageEmployerSettingsComponent,
        data: { title: 'Site Content' },
      },
      {
        path: 'departments',
        component: CustomerDepartmentsComponent,
        data: { title: 'Company Departments' },
      },
      {
        path: 'departments/detail',
        component: CustomerDepartmentsComponent,
        data: { title: 'Company Department Detail' },
      },
      {
        path: 'locations',
        component: CustomerLocationsComponent,
        data: { title: 'Company Locations' },
      },
      {
        path: 'locations/detail',
        component: CustomerLocationsComponent,
        data: { title: 'Company Location Detail' },
      },
      {
        path: 'hero',
        component: CareersPageHeroComponent,
        data: { title: 'Hero Section' },
      },
      {
        path: 'social',
        component: CustomerSocialMediaComponent,
        data: { title: 'Social Media' },
      },

      {
        canActivate: [FeatureGuard],
        path: 'interview-questions',
        component: InterviewQuestionsListComponent,
        data: { feature: employerInterviewQuestions, title: 'Interview Questions' },
      },
      {
        path: 'compliance',
        component: CustomerComplianceComponent,
        data: { title: 'Compliance' },
      },
      {
        path: 'automation-settings',
        component: JobsPostAutomationCompanySettingsComponent,
        data: { title: 'Automation Settings' },
      },
      {
        path: '**',
        component: FileNotFoundComponent,
        data: { title: NavItemNames.FileNotFound },
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerRoutingModule {}
