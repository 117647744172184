import { Validators } from '@angular/forms'
import {
  e11FieldInputDynamicComponent,
  e11FieldInputNumber,
  e11FieldInputSelect,
  e11FieldInputSwitch,
  e11FieldInputTextArea,
  e11FormFor,
  E11FormForType,
  e11FormLayoutFullWidth,
  e11FormLayoutInlineGrid,
  e11LayoutGroup,
  IFormTemplateSpec,
} from '@engineering11/ui-lib/e11-form-builder'
import { E11MarkdownEditorComponent } from '@engineering11/ui-lib/e11-markdown-editor'
import { ISelectOption } from '@engineering11/ui-lib/e11-select'
import { INPUTS_MAXLENGTH_BY_CONTEXT, InputsContext, MessagingSystemUserIds } from 'shared-lib'
import { delayUnitSelectOptions, ISendMessageFormData } from './job-automation.model'

export interface IAutomationFormConfig {
  senderOptions: ISelectOption[]
  canEditMessages: boolean
  togglePosition?: 'top' | 'bottom'
  showStandaloneToggle?: boolean
}

function sendMessageToggleLayout(defaults: Partial<ISendMessageFormData>, showStandaloneToggle?: boolean) {
  return e11FormLayoutFullWidth(
    [
      e11FieldInputSwitch('enabled', 'Enabled', defaults.enabled, {
        validatorOrOpts: [],
        hideInputError: true,
      }),
    ],
    {
      templateSpec: [
        {
          templateKey: 'messageFootNoteTemplate',
          templatePosition: 'bottom',
        },
      ],
      containerClass: !showStandaloneToggle ? 'e11-hidden' : '',
    }
  )
}

export function sendMessageForm(defaults: Partial<ISendMessageFormData>, config: IAutomationFormConfig): E11FormForType<ISendMessageFormData> {
  const { canEditMessages, senderOptions } = config

  // Do not need to upgrade if we have already upgraded
  const templateSpec: IFormTemplateSpec[] = !canEditMessages
    ? [
        {
          templateKey: 'upgradeProductTemplate',
          templatePosition: 'bottom',
        },
      ]
    : []
  const resetFormTemplateSpec: IFormTemplateSpec[] = canEditMessages
    ? [
        {
          templateKey: 'resetFormTemplate',
          templatePosition: 'bottom',
        },
      ]
    : []

  const togglePosition = config.togglePosition ?? 'top'
  const toggleLayout = sendMessageToggleLayout(defaults, config.showStandaloneToggle)

  return e11FormFor<ISendMessageFormData>()([
    togglePosition === 'top' ? toggleLayout : { fields: [] },
    e11LayoutGroup(
      [
        e11LayoutGroup([
          {
            fields: [],
            layouts: [
              {
                fields: [getMessageControl(defaults, config)],
                templateSpec: resetFormTemplateSpec,
              },
            ],
          },
        ]),
        e11LayoutGroup([
          e11FormLayoutInlineGrid(
            [
              e11FieldInputNumber('delayDuration', 'Delay time', defaults.delayDuration, {
                validatorOrOpts: [Validators.required, Validators.min(0)],
                hideInputError: true,
                isDisabled: !canEditMessages,
              }),
              e11FieldInputSelect('delayUnits', 'Units', delayUnitSelectOptions, defaults.delayUnits, {
                validatorOrOpts: [Validators.required],
                hideInputError: true,
                isDisabled: !canEditMessages,
              }),
            ],
            {
              fieldsClass: 'e11-w-1/2',
              containerClass: 'xl:e11-w-1/2 e11-mb-4',
              templateSpec: [
                {
                  templateKey: 'delayTimeHeaderTemplate',
                  templatePosition: 'top',
                },
              ],
            }
          ),
          e11FormLayoutFullWidth(
            [
              e11FieldInputSelect('senderId', 'This message is from', senderOptions, defaults.senderId, {
                hideInputError: true,
                isDisabled: !canEditMessages,
                validatorOrOpts: [Validators.required],
              }),
            ],
            {
              fieldsClass: 'e11-w-2/3',
              containerClass: 'xl:e11-w-2/3 e11-mb-4',
            }
          ),
        ]),
      ],
      {
        layoutContainerClass: 'e11-grid e11-grid-cols-1 md:e11-grid-cols-2 e11-gap-4 ',
        templateSpec,
      }
    ),
    togglePosition === 'bottom' ? toggleLayout : { fields: [] },
  ])
}

export const systemSenderOption: ISelectOption = { name: 'Hiring Team', value: MessagingSystemUserIds.hiring_team }

function getMessageControl(defaults: Partial<ISendMessageFormData>, config: IAutomationFormConfig) {
  if (config.canEditMessages)
    return e11FieldInputDynamicComponent<'message', string | undefined, E11MarkdownEditorComponent>(
      'message',
      '',
      E11MarkdownEditorComponent,
      defaults.message,
      {
        extraInputOption: {
          fieldClassList: ['e11-h-24'],
          maxLength: INPUTS_MAXLENGTH_BY_CONTEXT[InputsContext.LargeText],
          editorOption: { footer: { action: [{ visible: false }], audio: [], attachment: [], social: [] } }, // Currently we are suppressing the footer until video recording is supported
        },
        validatorOrOpts: [Validators.required, Validators.maxLength(INPUTS_MAXLENGTH_BY_CONTEXT[InputsContext.LargeText])],
      }
    )
  else
    return e11FieldInputTextArea('message', 'Message', defaults.message, {
      extraInputOption: { inputClass: 'e11-h-24' },
      isDisabled: true,
      validatorOrOpts: [Validators.required],
    })
}
