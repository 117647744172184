import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { IEmployerUser } from '@cnect/user-shared'
import { CustomerService } from '@engineering11/customer-web'
import { FormCustomValidationService, LocalStorageService, QuestionType, getCurrentUser, getCurrentUserEmployer } from 'shared-lib'
import { E11ConfirmationComponent } from '@engineering11/ui-lib/e11-confirmation'
import { Form, FormControl, FormGroup, NgForm, Validators } from '@angular/forms'
import { NotificationService } from '@engineering11/web-ui-helpers'
import { Validation } from '@engineering11/ui-lib/e11-input-errors'
import { IInterviewQuestion } from '../../interview-question.model'
import { v4 as uuidv4 } from 'uuid'
import { Store } from '@ngrx/store'
import { InterviewQuestionCategoriesComponent } from '../interview-question-categories/interview-question-categories.component'
import { InterviewQuestionTagsComponent } from '../interview-question-tags/interview-question-tags.component'
import { INotificationMessage } from '@engineering11/ui-lib/e11-notifications'
import { Observable, Subject, filter, takeUntil } from 'rxjs'
import { isNotNil } from '@engineering11/utility'
import { ReplaceValues } from '@engineering11/types'
import { Optional } from '@engineering11/types'

type EditableFields = Omit<IInterviewQuestion, 'customerKey' | 'id' | 'date' | 'authorId' | 'authorName'>
export type InterviewQuestionForm = ReplaceValues<EditableFields, FormControl<any>>

@Component({
  selector: 'ui-interview-question-form',
  templateUrl: './interview-question-form.component.html',
  styleUrls: ['./interview-question-form.component.scss'],
})
export class InterviewQuestionFormComponent implements OnInit {
  user$ = this.store.pipe(getCurrentUserEmployer, filter(isNotNil))
  loading: boolean = true
  maxQuestionLength = 500
  destroy$: Subject<boolean> = new Subject<boolean>()

  radioState: QuestionType = QuestionType.Essay
  questionFormSubmitted: boolean = false
  questionFormVisable: boolean = false

  QuestionType = QuestionType

  @ViewChild('confirmation') confirmation!: E11ConfirmationComponent

  @Input() selectedQuestion?: IInterviewQuestion

  @Output() closeForm = new EventEmitter()
  @Output() questionDeleted = new EventEmitter()
  @Output() questionSubmitted = new EventEmitter()

  questionForm = new FormGroup<InterviewQuestionForm>({
    question: new FormControl('', [Validators.required, this.customValidator.whitespaceValidator(), Validators.maxLength(this.maxQuestionLength)]),
    inputType: new FormControl(QuestionType.Essay),
    tags: new FormControl([] as string[]),
    categories: new FormControl([] as string[]),
  })

  constructor(
    private store: Store,
    private customerService: CustomerService,
    private router: Router,
    private customValidator: FormCustomValidationService,
    private notificationsService: NotificationService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedQuestion = changes.selectedQuestion.currentValue
    if (!changes.selectedQuestion.firstChange) {
      this.changePatch(changes.selectedQuestion.currentValue)
    }
  }

  get f() {
    return this.questionForm.controls
  }

  notify() {
    this.notificationsService.popNotification(saveSuccessNotification)
  }

  deleteQuestion() {
    this.questionDeleted.emit(this.selectedQuestion)
  }

  changePatch(change: IInterviewQuestion) {
    if (!change) {
      this.resetPatch()
    } else {
      this.questionForm.patchValue({
        question: change.question,
        categories: change.categories,
        tags: change.tags,
        inputType: change.inputType,
      })
    }
  }

  resetPatch() {
    this.questionForm.patchValue({
      question: '',
      categories: [],
      tags: [],
      inputType: QuestionType.Essay,
    })
  }

  cancelForm() {
    this.closeForm.emit()
    this.questionForm.reset()
    this.resetPatch()
  }

  makeQuestion(user: IEmployerUser) {
    let newQuestion: Optional<IInterviewQuestion, 'id'> = {
      question: this.f.question.value!,
      categories: this.f.categories?.value ?? [],
      tags: this.f.tags?.value ?? [],
      inputType: this.f.inputType.value,
      authorId: user.id!,
      authorName: user.displayName!,
      date: new Date(),
      customerKey: user.customerKey,
    }

    return newQuestion
  }

  onSubmitFormQuestion(user: IEmployerUser) {
    this.questionFormSubmitted = true
    if (this.questionForm.valid) {
      this.notify()
      this.questionFormSubmitted = false
      this.questionSubmitted.emit(this.makeQuestion(user))
      this.cancelForm()
    } else {
      this.questionForm.updateValueAndValidity()
      Validation.validateAll(this.questionForm)
    }
  }
}

const saveSuccessNotification: INotificationMessage = {
  title: 'Success',
  message: 'Your question was saved successfully!',
  type: 'success',
  autoClose: true,
}
