import { CommonModule } from '@angular/common'
import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core'
import { ReactiveFormsModule, Validators } from '@angular/forms'
import {
  autoQuestionAutomationDefaults,
  IAutoQuestionAutomationFormData,
  IAutoQuestionFormData,
} from '@employer/app/components/jobs-automation/automation-auto-question/auto-questions.model'
import { AutomationAutoQuestionFormComponent } from '@employer/app/components/jobs-automation/automation-auto-question/automation-auto-question-form.component'
import { AutomationDelayInfoComponent } from '@employer/app/components/jobs-automation/partials/automation-delay-info.component'
import { AutomationReminderInfoComponent } from '@employer/app/components/jobs-automation/partials/automation-reminder-info.component'
import { AutomationResetComponent } from '@employer/app/components/jobs-automation/partials/automation-reset.component'
import { AutomationUpgradePromptComponent } from '@employer/app/components/jobs-automation/partials/automation-upgrade-prompt.component'

import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import { E11DividerModule } from '@engineering11/ui-lib/e11-divider'
import {
  E11DynamicFormBuilderComponent,
  E11DynamicFormModule,
  e11FieldInputMultiSelector,
  e11FieldInputSelect,
  e11FieldInputSwitch,
  e11FormFor,
  E11FormForType,
  e11FormLayoutFullWidth,
  e11LayoutGroup,
} from '@engineering11/ui-lib/e11-form-builder'
import { E11InputLabelModule } from '@engineering11/ui-lib/e11-input-label'
import { E11ShowHideControllerComponent, E11ShowHideWrapperComponent } from '@engineering11/ui-lib/e11-show-hide'
import { E11SwitchModule } from '@engineering11/ui-lib/e11-switch'
import { E11Logger } from '@engineering11/web-api-error'
import { TranslateModule } from '@ngx-translate/core'
import { APPLICATION_STATE, FormSelectService } from 'shared-lib'
import { IRequestMoreFormData, IRequestUpdatesFormData, requestedFieldOptions } from './auto-request-updates.model'
import { IApplicationAutoResponseFormData } from './auto-response.model'
import { AutomationCandidateRejectionFormComponent } from './automation-candidate-rejection/automation-candidate-rejection-form.component'
import { IRejectCandidateFormData, rejectCandidateDefaults } from './automation-candidate-rejection/candidate-rejection.model'
import { IAutomationFormConfig, sendMessageForm } from './automation-form-message'
import { automationFormDefaults, IAutomationFormData } from './automation-form.model'
import { AutomationCandidateUnresponsiveFormComponent } from './automation-unresponsive-candidate/automation-candidate-unresponsive-form.component'
import { IUnresponsiveCandidateFormData, unresponsiveCandidateDefaults } from './automation-unresponsive-candidate/unresponsive-candidate.model'
import { ISendMessageFormData } from './job-automation.model'
import { AutomationRequestedUpdateHelperComponent } from './partials/automation-requested-update-helper.component'
import { AutomationAutoResponseHelperComponent } from './partials/automation-auto-response-helper.component'
import { E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'

export interface IAutomationForms {
  autoResponse: E11FormForType<ISendMessageFormData>
  requestedUpdate: E11FormForType<IRequestUpdatesFormData>
  requestMoreInitial: E11FormForType<ISendMessageFormData>
  requestMoreFollowUp: E11FormForType<ISendMessageFormData>
  requestMoreWarning: E11FormForType<ISendMessageFormData>
}

@Component({
  selector: 'job-automation-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    E11DynamicFormModule,
    E11InputLabelModule,
    E11SwitchModule,
    TranslateModule,
    E11ButtonModule,
    E11DividerModule,
    E11ShowHideControllerComponent,
    E11ShowHideWrapperComponent,
    AutomationDelayInfoComponent,
    AutomationResetComponent,
    AutomationReminderInfoComponent,
    AutomationUpgradePromptComponent,
    AutomationAutoQuestionFormComponent,
    AutomationCandidateUnresponsiveFormComponent,
    AutomationCandidateRejectionFormComponent,
    AutomationRequestedUpdateHelperComponent,
    AutomationAutoResponseHelperComponent,
  ],
  template: `
    <ng-container *ngIf="forms">
      <div class="e11-flex e11-justify-between e11-items-center ">
        <h1>{{ formHeader }}</h1>
        <e11-button
          value="{{ 'Save changes' | translate }}"
          [size]="'sm'"
          [color]="'primary-accent'"
          [type]="'button'"
          [loading]="saving"
          [loadingText]="'Saving...'"
          (click)="!saving && saveClicked()"
        >
        </e11-button>
      </div>
      <e11-divider [margin]="1"></e11-divider>
      <div class="e11-flex e11-flex-col e11-gap-2 e11-mb-2">
        <div class="e11-flex e11-gap-2 e11-items-center">
          <h3 class="e11-text-skin-primary-accent">{{ 'Application Auto Response' | translate }}</h3>
          <e11-switch
            [checked]="autoResponseForm?.form?.get('enabled')?.value ?? false"
            (changes)="autoResponseForm?.form?.get('enabled')?.setValue($event)"
            [size]="'xs'"
          ></e11-switch>
          <e11-show-hide-controller
            [containerClass]="'e11-text-skin-primary-accent'"
            [showText]="'Show Settings'"
            [hideText]="'Hide Settings'"
            [isCollapsed]="isAutoResponseCollapsed"
            (toggle)="isAutoResponseCollapsed = !isAutoResponseCollapsed"
          >
          </e11-show-hide-controller>
        </div>
      </div>
      <e11-show-hide-wrapper [isCollapsed]="isAutoResponseCollapsed">
        <div>
          <span class="e11-mr-1"> {{ 'Automatically send messages to candidates as they complete their application.' }}</span>
          <job-automation-auto-response-helper></job-automation-auto-response-helper>
        </div>
        <e11-dynamic-form-builder
          #autoResponseForm
          [loading]="loading"
          [fieldGroups]="forms.autoResponse"
          [initialValue]="formData.autoResponse"
          [hideSubmitMenu]="true"
          (valueChanges)="autoResponse = $event"
        >
          <ng-template e11Template="messageFootNoteTemplate">
            <div class="e11-my-4">
              <span>{{ 'Sent to the candidates as their applications are submitted' | translate }}</span>
            </div>
          </ng-template>
          <ng-template e11Template="delayTimeHeaderTemplate">
            <automation-delay-info></automation-delay-info>
          </ng-template>
          <ng-template e11Template="upgradeProductTemplate">
            <automation-upgrade-prompt></automation-upgrade-prompt>
          </ng-template>
          <ng-template e11Template="resetFormTemplate">
            <automation-reset
              *ngIf="showResetButton"
              [formField]="'autoResponse'"
              [loading]="loading"
              (onFormFieldReset)="onFormFieldReset(autoResponseForm, $event)"
            ></automation-reset>
          </ng-template>
        </e11-dynamic-form-builder>
      </e11-show-hide-wrapper>

      <div class="e11-flex e11-gap-2 e11-items-center">
        <h3 class="e11-text-skin-primary-accent e11-mb-2">{{ 'Request Updates' | translate }}</h3>
        <e11-switch
          [checked]="requestUpdatesForm?.form?.get('enabled')?.value ?? false"
          (changes)="requestUpdatesForm?.form?.get('enabled')?.setValue($event)"
          [size]="'xs'"
        ></e11-switch>
        <e11-show-hide-controller
          [containerClass]="'e11-text-skin-primary-accent'"
          [showText]="'Show Settings'"
          [hideText]="'Hide Settings'"
          [isCollapsed]="isRequestUpdatesCollapsed"
          (toggle)="isRequestUpdatesCollapsed = !isRequestUpdatesCollapsed"
        >
        </e11-show-hide-controller>
      </div>
      <e11-show-hide-wrapper [isCollapsed]="isRequestUpdatesCollapsed">
        <p>
          {{
            'Choose the parts of your applications you find important and let the system automatically ask the candidate to provide more information if these areas are left blank.'
              | translate
          }}
          <job-automation-requested-update-helper></job-automation-requested-update-helper>
        </p>
        <e11-dynamic-form-builder
          #requestUpdatesForm
          [loading]="loading"
          [fieldGroups]="forms.requestedUpdate"
          [initialValue]="formData.requestMore.requestedUpdate"
          [hideSubmitMenu]="true"
          (valueChanges)="requestUpdates = $event"
        >
          <ng-template e11Template="requestMoreInfo">
            <div class="">
              <div class="e11-font-bold">{{ 'What to check' | translate }}</div>
              <p>{{ 'If these areas of the application are left blank we will ask the candidate to supply information.' | translate }}</p>
            </div>
          </ng-template>
          <ng-template e11Template="swimlaneInfo">
            <div class="">
              <div class="e11-font-bold">{{ 'Where to go next' | translate }}</div>
              <p>{{ 'After the candidate completes these requests where do you want to place the candidate in your pipeline?' | translate }}</p>
            </div>
          </ng-template>
          <ng-template e11Template="resetFormTemplate">
            <automation-reset
              *ngIf="showResetButton"
              [formField]="'requestedUpdate'"
              [loading]="loading"
              (onFormFieldReset)="onFormFieldReset(requestUpdatesForm, $event)"
            ></automation-reset>
          </ng-template>
        </e11-dynamic-form-builder>

        <div class="e11-flex e11-gap-2 e11-items-center">
          <h4 class="e11-mb-2 e11-font-bold">{{ 'Initial Message' | translate }}</h4>
          <e11-switch
            [checked]="initialMessageForm.form.get('enabled')?.value ?? false"
            (changes)="initialMessageForm.form.get('enabled')?.setValue($event)"
            [size]="'xs'"
          ></e11-switch>
        </div>
        <e11-dynamic-form-builder
          #initialMessageForm
          [loading]="loading"
          [fieldGroups]="forms.requestMoreInitial"
          [initialValue]="formData.requestMore.initialMessage"
          [hideSubmitMenu]="true"
          (valueChanges)="initialMessage = $event"
        >
          <ng-template e11Template="messageFootNoteTemplate">
            <div class="e11-my-4 ">
              <span>{{
                'Sent to the candidates after the application is submitted, reviewed and the sections listed have been determined to be lacking any data.'
                  | translate
              }}</span>
            </div>
          </ng-template>
          <ng-template e11Template="delayTimeHeaderTemplate">
            <automation-delay-info></automation-delay-info>
          </ng-template>
          <ng-template e11Template="upgradeProductTemplate">
            <automation-upgrade-prompt></automation-upgrade-prompt>
          </ng-template>
          <ng-template e11Template="resetFormTemplate">
            <automation-reset
              *ngIf="showResetButton"
              [formField]="'requestMoreInitial'"
              [loading]="loading"
              (onFormFieldReset)="onFormFieldReset(initialMessageForm, $event)"
            ></automation-reset>
          </ng-template>
        </e11-dynamic-form-builder>

        <div class="e11-flex e11-gap-2 e11-items-center">
          <h4 class="e11-mb-2 e11-font-bold">{{ 'Follow up' | translate }}</h4>
          <e11-switch
            [checked]="followUpMessageForm.form.get('enabled')?.value ?? false"
            (changes)="followUpMessageForm.form.get('enabled')?.setValue($event)"
            [size]="'xs'"
          ></e11-switch>
        </div>
        <e11-dynamic-form-builder
          #followUpMessageForm
          [loading]="loading"
          [fieldGroups]="forms.requestMoreFollowUp"
          [initialValue]="formData.requestMore.followUp"
          [hideSubmitMenu]="true"
          (valueChanges)="followUpMessage = $event"
        >
          <ng-template e11Template="messageFootNoteTemplate">
            <automation-reminder-info></automation-reminder-info>
          </ng-template>
          <ng-template e11Template="delayTimeHeaderTemplate">
            <automation-delay-info></automation-delay-info>
          </ng-template>
          <ng-template e11Template="upgradeProductTemplate">
            <automation-upgrade-prompt></automation-upgrade-prompt>
          </ng-template>
          <ng-template e11Template="resetFormTemplate">
            <automation-reset
              *ngIf="showResetButton"
              [formField]="'requestMoreFollowUp'"
              [loading]="loading"
              (onFormFieldReset)="onFormFieldReset(followUpMessageForm, $event)"
            ></automation-reset>
          </ng-template>
        </e11-dynamic-form-builder>
        <div class="e11-flex e11-gap-2 e11-items-center">
          <h4 class="e11-mb-2 e11-font-bold">{{ 'Warning' | translate }}</h4>
          <e11-switch
            [checked]="warningMessageForm.form.get('enabled')?.value ?? false"
            (changes)="warningMessageForm.form.get('enabled')?.setValue($event)"
            [size]="'xs'"
          ></e11-switch>
        </div>
        <e11-dynamic-form-builder
          #warningMessageForm
          [loading]="loading"
          [fieldGroups]="forms.requestMoreWarning"
          [initialValue]="formData.requestMore.warning"
          [hideSubmitMenu]="true"
          (valueChanges)="warningMessage = $event"
        >
          <ng-template e11Template="messageFootNoteTemplate">
            <automation-reminder-info></automation-reminder-info>
          </ng-template>
          <ng-template e11Template="delayTimeHeaderTemplate">
            <automation-delay-info></automation-delay-info>
          </ng-template>
          <ng-template e11Template="upgradeProductTemplate">
            <automation-upgrade-prompt></automation-upgrade-prompt>
          </ng-template>
          <ng-template e11Template="resetFormTemplate">
            <automation-reset
              *ngIf="showResetButton"
              [formField]="'requestMoreWarning'"
              [loading]="loading"
              (onFormFieldReset)="onFormFieldReset(warningMessageForm, $event)"
            ></automation-reset>
          </ng-template>
        </e11-dynamic-form-builder>
      </e11-show-hide-wrapper>

      <job-automation-auto-question-form
        *ngIf="showAutoQuestionForm"
        [loading]="loading"
        [formData]="formData.autoQuestions"
        [formConfig]="_formConfig"
        (valueChanges)="autoQuestions = $event"
        (saveQuestionsChanges)="saveAutoQuestions(formData, $event)"
      ></job-automation-auto-question-form>

      <e11-divider [margin]="1"></e11-divider>

      <job-automation-candidate-unresponsive-form
        *ngIf="showUnresponsiveCandidateForm"
        [loading]="loading"
        [formData]="formData.unresponsiveCandidate"
        (valueChanges)="unresponsiveCandidate = $event"
      ></job-automation-candidate-unresponsive-form>

      <job-automation-candidate-rejection-form
        *ngIf="showRejectionCandidateForm"
        [loading]="loading"
        [formData]="formData.rejectCandidate"
        (valueChanges)="rejectCandidate = $event"
      ></job-automation-candidate-rejection-form>
    </ng-container>
  `,
})
export class JobAutomationFormComponent implements AfterViewChecked {
  @ViewChild('autoResponseForm') autoResponseForm!: E11DynamicFormBuilderComponent<IApplicationAutoResponseFormData>
  @ViewChild('requestUpdatesForm') requestUpdatesForm!: E11DynamicFormBuilderComponent<IRequestUpdatesFormData>
  @ViewChild('initialMessageForm') initialMessageForm!: E11DynamicFormBuilderComponent<ISendMessageFormData>
  @ViewChild('followUpMessageForm') followUpMessageForm!: E11DynamicFormBuilderComponent<ISendMessageFormData>
  @ViewChild('warningMessageForm') warningMessageForm!: E11DynamicFormBuilderComponent<ISendMessageFormData>

  @Input() saving = false
  @Input() loading = false
  @Input() showResetButton = false
  @Input() showAutoQuestionForm = false
  @Input() showUnresponsiveCandidateForm = false
  @Input() showRejectionCandidateForm = false
  @Input() formHeader = 'Manage Automation'
  @Input() formData: IAutomationFormData = automationFormDefaults
  _formConfig?: IAutomationFormConfig
  @Input() set formConfig(config: IAutomationFormConfig) {
    this._formConfig = config
    // Do changes to hiring team reconstruct the form? e.g. Do they clear existing inputs?
    this.forms = this.buildForms(config)
  }

  isAutoResponseCollapsed = false
  isRequestUpdatesCollapsed = false
  forms?: IAutomationForms
  @Output() newFormData = new EventEmitter<IAutomationFormData>()

  autoResponse?: IApplicationAutoResponseFormData
  requestUpdates?: IRequestUpdatesFormData
  initialMessage?: ISendMessageFormData
  followUpMessage?: ISendMessageFormData
  warningMessage?: ISendMessageFormData

  autoQuestions?: IAutoQuestionAutomationFormData
  unresponsiveCandidate?: IUnresponsiveCandidateFormData
  rejectCandidate?: IRejectCandidateFormData

  #logger = inject(E11Logger)

  constructor(private formSelectService: FormSelectService, private cdr: ChangeDetectorRef, private notification: E11NotificationsService) {}

  ngAfterViewChecked() {
    this.cdr.detectChanges() // Suppresses NG0100 on
  }

  validateForms() {
    const forms = [this.autoResponseForm, this.requestUpdatesForm, this.initialMessageForm, this.followUpMessageForm, this.warningMessageForm]
    // run validation on all forms
    forms.forEach(form => form.onSubmitClick())

    return forms.every(form => form.form.valid)
  }

  saveClicked() {
    if (!this.validateForms()) {
      this.notification.popNotificationMessage({
        message: 'Please ensure all fields are filled out correctly',
        type: 'error',
        autoClose: true,
        title: 'Could not save automation',
        dismissOnRouteChange: true,
      })
      return
    }
    // Instead of validation, we force defaults on the user if nothing is included
    const autoResponse: IApplicationAutoResponseFormData = { ...automationFormDefaults.autoResponse, ...this.autoResponse }
    const requestMore: IRequestMoreFormData = {
      requestedUpdate: { ...automationFormDefaults.requestMore.requestedUpdate, ...this.requestUpdates },
      initialMessage: { ...automationFormDefaults.requestMore.initialMessage, ...this.initialMessage },
      followUp: { ...automationFormDefaults.requestMore.followUp, ...this.followUpMessage },
      warning: { ...automationFormDefaults.requestMore.warning, ...this.warningMessage },
    }
    const autoQuestions: IAutoQuestionAutomationFormData = { ...autoQuestionAutomationDefaults, ...this.autoQuestions }
    const unresponsiveCandidate: IUnresponsiveCandidateFormData = {
      ...unresponsiveCandidateDefaults,
      ...this.unresponsiveCandidate,
    }
    const rejectCandidate: IRejectCandidateFormData = {
      ...rejectCandidateDefaults,
      ...this.rejectCandidate,
    }
    const formData: IAutomationFormData = { requestMore, autoResponse, autoQuestions, unresponsiveCandidate, rejectCandidate }
    this.#logger.log('JobAutomationFormComponent.saveClicked', formData)
    this.newFormData.emit(formData)
  }

  onFormFieldReset(
    form: E11DynamicFormBuilderComponent<IRequestUpdatesFormData> | E11DynamicFormBuilderComponent<ISendMessageFormData>,
    companyData: ISendMessageFormData | IRequestUpdatesFormData
  ) {
    form.reset(companyData as any)
  }

  private buildForms(config: IAutomationFormConfig) {
    return {
      autoResponse: sendMessageForm(automationFormDefaults.autoResponse, config),
      requestedUpdate: this.buildRequestedUpdateForm(),
      requestMoreInitial: sendMessageForm(automationFormDefaults.requestMore.initialMessage, config),
      requestMoreFollowUp: sendMessageForm(automationFormDefaults.requestMore.followUp, config),
      requestMoreWarning: sendMessageForm(automationFormDefaults.requestMore.warning, config),
    }
  }

  private buildRequestedUpdateForm(): E11FormForType<IRequestUpdatesFormData> {
    const { enabled, fields, newSwimlane } = automationFormDefaults.requestMore.requestedUpdate
    const swimlaneOptions = this.formSelectService
      .getApplicationStateOptions()
      .filter(s => [APPLICATION_STATE.SCREENED, APPLICATION_STATE.INTERVIEWING, APPLICATION_STATE.FINALIST].includes(s.value))
    return e11FormFor<IRequestUpdatesFormData>()([
      e11FormLayoutFullWidth(
        [
          e11FieldInputSwitch('enabled', 'Enabled', enabled, {
            validatorOrOpts: [],
          }),
        ],
        { containerClass: 'e11-hidden' }
      ),
      e11LayoutGroup(
        [
          e11LayoutGroup([
            e11FormLayoutFullWidth(
              [
                e11FieldInputMultiSelector('fields', '', requestedFieldOptions, fields, {
                  validatorOrOpts: [Validators.required],
                }),
              ],
              { templateSpec: { templateKey: 'requestMoreInfo', templatePosition: 'top' } }
            ),
          ]),
          e11LayoutGroup([
            e11FormLayoutFullWidth(
              [
                e11FieldInputSelect('newSwimlane', 'New Swimlane', swimlaneOptions, newSwimlane, {
                  validatorOrOpts: [Validators.required],
                }),
              ],
              { templateSpec: { templateKey: 'swimlaneInfo', templatePosition: 'top' } }
            ),
          ]),
        ],
        {
          layoutContainerClass: 'e11-grid e11-grid-cols-1 md:e11-grid-cols-2 e11-gap-4',
          templateSpec: { templateKey: 'resetFormTemplate', templatePosition: 'bottom' },
        }
      ),
    ])
  }

  /**
   * Saves ONLY the questions changes made from the autoQuestion modal
   */
  saveAutoQuestions(existingFormData: IAutomationFormData, autoQuestions: IAutoQuestionFormData[]) {
    this.newFormData.emit({
      ...existingFormData,
      autoQuestions: { enabled: existingFormData.autoQuestions.enabled, autoQuestions },
    })
  }
}

/**
 * Options:
 * 1: Save will only save if all forms pass
 * 2: Save saves the passing forms and not the failing forms?
 * 3: Save saves all forms?
 */
