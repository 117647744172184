import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core'
import { E11DynamicFormBuilderComponent, E11DynamicFormModule, E11FormForType } from '@engineering11/ui-lib/e11-form-builder'
import { AutomationDelayInfoComponent } from '@employer/app/components/jobs-automation/partials/automation-delay-info.component'
import { AutomationUpgradePromptComponent } from '@employer/app/components/jobs-automation/partials/automation-upgrade-prompt.component'
import { IAutomationFormConfig, sendMessageForm } from '@employer/app/components/jobs-automation/automation-form-message'
import { TranslateModule } from '@ngx-translate/core'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'
import { ISendMessageFormData } from '../../../job-automation.model'

@Component({
  selector: 'automated-question-message',
  standalone: true,
  imports: [CommonModule, E11DynamicFormModule, AutomationDelayInfoComponent, AutomationUpgradePromptComponent, TranslateModule, E11TooltipModule],
  template: `
    <ng-container *ngIf="forms">
      <div class=" e11-mt-6 e11-mb-2">
        <span *ngIf="title">{{ title }}</span>
      </div>
      <e11-dynamic-form-builder
        #messageForm
        [fieldGroups]="forms"
        [initialValue]="initialValue"
        [loading]="loading"
        [hideSubmitMenu]="hideSubmitMenu"
        (valueChanges)="messageData = $event"
      >
        <ng-template e11Template="delayTimeHeaderTemplate">
          <automation-delay-info></automation-delay-info>
        </ng-template>
        <ng-template e11Template="upgradeProductTemplate">
          <automation-upgrade-prompt></automation-upgrade-prompt>
        </ng-template>
        <ng-template e11Template="resetFormTemplate">
          <div class="e11-flex e11-gap-4 e11-text-sm e11-mb-4">
            <e11-tooltip [color]="'dark'" [tooltipText]="tooltipText">
              <button *ngIf="!loading" (click)="onResetFormData()" type="button" class="e11-text-skin-primary-accent e11-flex e11-items-center">
                <span class="material-icons-outlined md-18">replay</span>
                {{ 'Reset' | translate }}
              </button>
            </e11-tooltip>
          </div>
        </ng-template>
      </e11-dynamic-form-builder>
    </ng-container>
  `,
})
export class AutomatedQuestionMessage implements AfterViewInit {
  @ViewChild('messageForm') messageForm!: E11DynamicFormBuilderComponent<ISendMessageFormData>

  @Input() title: string = ''
  @Input() tooltipText: string = ''
  @Input() saving: boolean = false
  @Input() loading: boolean = false
  @Input() showResetButton: boolean = false
  @Input() hideSubmitMenu: boolean = true

  @Input({ required: true }) initialValue!: ISendMessageFormData
  @Input({ required: true }) defaultValue!: ISendMessageFormData

  _formConfig?: IAutomationFormConfig
  @Input() set formConfig(config: IAutomationFormConfig) {
    this._formConfig = config
    if (this.defaultValue) {
      this.forms = this.buildForms(config)
    }
  }

  forms?: E11FormForType<ISendMessageFormData>

  messageData?: ISendMessageFormData

  ngAfterViewInit(): void {
    if (this.defaultValue && this._formConfig) {
      this.forms = this.buildForms(this._formConfig)
    }
  }

  onResetFormData() {
    this.initialValue = { ...this.defaultValue }
    this.messageForm.reset(this.initialValue)
  }

  private buildForms(config: IAutomationFormConfig) {
    return sendMessageForm(this.defaultValue, { ...config, showStandaloneToggle: true, togglePosition: 'bottom' })
  }
}
