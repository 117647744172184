import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { ReactiveFormsModule, Validators } from '@angular/forms'
import { IEmployerUser } from '@cnect/user-shared'
import { AddInterviewQuestionRequest, IInterviewQuestion } from '@employer/app/modules/interview-question-library/interview-question.model'
import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import { E11InputCheckboxModule } from '@engineering11/ui-lib/e11-input-checkbox'
import { E11InputErrorsModule } from '@engineering11/ui-lib/e11-input-errors'
import { E11NavExpanderModule } from '@engineering11/ui-lib/e11-nav-expander'
import { E11RadioInputModule } from '@engineering11/ui-lib/e11-radio-input'
import { E11TextareaModule } from '@engineering11/ui-lib/e11-textarea'
import { isNotNil, uuid } from '@engineering11/utility'
import { ViewportService } from '@engineering11/web-utilities'
import { Store } from '@ngrx/store'
import { TranslateModule } from '@ngx-translate/core'
import { filter, map, Observable, Subscription } from 'rxjs'
import { CnectFeatures, ConfigStore, FormCustomValidationService, IQuestionUpdate, QuestionType, getCurrentUserEmployer } from 'shared-lib'
import { LetDirective } from '@ngrx/component'
import {
  E11DynamicFormBuilderComponent,
  E11DynamicFormModule,
  e11FieldInputCheckBox,
  e11FieldInputRadio,
  e11FieldInputTextArea,
  e11FormFor,
  E11FormForType,
  e11FormLayoutFullWidth,
  e11LayoutGroup,
} from '@engineering11/ui-lib/e11-form-builder'
import { isEqual } from 'lodash'
import {
  IWriteInterviewFormArgs,
  IWriteInterviewFormData,
} from '@employer/app/components/jobs-automation/automation-auto-question/partials/automated-question-interview-form/automated-question-interview.model'

@Component({
  selector: 'write-job-interview',
  standalone: true,
  imports: [
    E11NavExpanderModule,
    ReactiveFormsModule,
    E11TextareaModule,
    E11RadioInputModule,
    E11ButtonModule,
    E11InputCheckboxModule,
    E11DynamicFormModule,
    TranslateModule,
    E11InputErrorsModule,
    CommonModule,
    LetDirective,
  ],
  template: `
    <ng-container *ngrxLet="isMobile$ as isMobile">
      <ng-container *ngrxLet="user$ as user">
        <div class="e11-pb-2 e11-w-full">
          <e11-dynamic-form-builder
            #writeInterviewForm
            [fieldGroups]="writeFormFieldGroup"
            [initialValue]="writeFormData"
            [hideSubmitMenu]="true"
            (valueChanges)="handleValueChange($event)"
          >
            <ng-template e11Template="questionCounterTemplate">
              <div class="e11-flex e11-justify-end">
                <span
                  class="e11-text-xs"
                  [ngClass]="{
                    'e11-text-skin-error': writeFormData.question.length > maxQuestionLength,
                    'e11-text-skin-neutral': writeFormData.question.length <= maxQuestionLength
                  }"
                >
                  {{ writeFormData.question?.length }} / {{ maxQuestionLength }}
                </span>
              </div>
            </ng-template>
          </e11-dynamic-form-builder>
          <div class="e11-flex e11-my-4">
            <e11-button
              [color]="'primary-accent'"
              [type]="'submit'"
              [buttonGroup]="true"
              value="{{
                (questionToEdit ? (isMobile ? mobileSaveButtonLabel : saveButtonLabel) : isMobile ? mobileAddButtonLabel : addButtonLabel) | translate
              }}"
              (click)="onSubmitFormQuestion(user)"
            ></e11-button>
            <e11-button
              *ngIf="questionToEdit"
              (click)="cancelEdit()"
              [color]="'neutral'"
              [ghost]="true"
              [buttonGroup]="true"
              value="{{ isMobile ? 'Cancel' : ('Cancel edit' | translate) }}"
            ></e11-button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  `,
})
export class WriteJobInterviewComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild('writeInterviewForm') writeInterviewForm?: E11DynamicFormBuilderComponent<IWriteInterviewFormData>
  @Input() questionToEdit?: IInterviewQuestion // editing this question
  @Input() addButtonLabel = 'Add Question'
  @Input() mobileAddButtonLabel = 'Add Question'
  @Input() saveButtonLabel = 'Save Question'
  @Input() mobileSaveButtonLabel = 'Save Question'
  @Input() maxQuestionLength = 500
  @Output() onSaveToLibrary = new EventEmitter<AddInterviewQuestionRequest>()
  @Output() onAddQuestion = new EventEmitter<AddInterviewQuestionRequest>()
  @Output() onUpdateQuestion = new EventEmitter<IQuestionUpdate>()
  @Output() onEditCancelled = new EventEmitter()

  writeFormFieldGroup: E11FormForType<IWriteInterviewFormData> = this.buildForm({ disableSaveToLibrary: !!this.questionToEdit })
  writeFormData: IWriteInterviewFormData = {
    question: this.questionToEdit?.question ?? '',
    inputType: this.questionToEdit?.inputType ?? QuestionType.Essay,
    saveToLibrary: false,
  }
  user$ = this.store.pipe(getCurrentUserEmployer, filter(isNotNil))
  features$: Observable<CnectFeatures | undefined> = this.configStore.features$

  isMobile$ = this.viewportService.viewportSizeChanged$.pipe(
    map(data => data == 'sm' || data == 'xs'),
    filter(isNotNil)
  )

  constructor(
    private customValidator: FormCustomValidationService,
    private store: Store,
    private configStore: ConfigStore,
    private viewportService: ViewportService
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.questionToEdit && changes.questionToEdit.currentValue?.id !== changes.questionToEdit.previousValue?.id) {
      this.writeFormData = {
        question: changes.questionToEdit.currentValue?.question ?? '',
        inputType: changes.questionToEdit.currentValue?.inputType,
        saveToLibrary: false,
      }
      this.writeFormFieldGroup = this.buildForm({ disableSaveToLibrary: !!this.questionToEdit })
    }
  }

  subscription!: Subscription
  isSaveToLibraryHidden = false

  ngOnInit() {
    this.subscription = this.features$.pipe(map(features => !features?.employerInterviewQuestions)).subscribe(isHidden => {
      this.isSaveToLibraryHidden = isHidden
    })
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  resetPatch() {
    this.writeFormData = {
      question: '',
      inputType: QuestionType.Essay,
      saveToLibrary: false,
    }
    this.writeInterviewForm?.reset(this.writeFormData)
  }

  onSubmitFormQuestion(user: IEmployerUser) {
    this.writeInterviewForm?.onSubmitClick()
    if (this.writeInterviewForm?.form.invalid) {
      return
    }
    if (this.questionToEdit) {
      this.updateExistingQuestion(this.questionToEdit)
    } else {
      this.addNewQuestion(user)
    }

    this.resetForm()
  }

  cancelEdit() {
    this.resetForm()
    this.onEditCancelled.emit()
  }

  private addNewQuestion(user: IEmployerUser) {
    const newQuestion = this.makeInterviewQuestion(user)
    if (this.writeFormData.saveToLibrary) {
      this.onSaveToLibrary.emit(newQuestion)
    }
    this.onAddQuestion.emit({
      ...newQuestion,
      inputType: newQuestion.inputType,
    })
  }

  private buildForm({ disableSaveToLibrary }: IWriteInterviewFormArgs): E11FormForType<IWriteInterviewFormData> {
    return e11FormFor<IWriteInterviewFormData>()([
      e11LayoutGroup([
        e11LayoutGroup(
          [
            e11FormLayoutFullWidth(
              [
                e11FieldInputTextArea('question', 'Interview Question', undefined, {
                  validatorOrOpts: [Validators.required, this.customValidator.whitespaceValidator(), Validators.maxLength(this.maxQuestionLength)],
                  extraInputOption: {
                    placeholder: 'Your question here',
                    // enforceCharacterLimit: true,
                    maxLength: this.maxQuestionLength,
                    required: true,
                  },
                }),
              ],
              {
                containerClass: 'e11-w-full e11-mb-4',
              }
            ),
          ],
          {
            layoutContainerClass: ' ',
            templateSpec: [
              {
                templateKey: 'questionCounterTemplate',
                templatePosition: 'bottom',
              },
            ],
          }
        ),
        e11FormLayoutFullWidth(
          [
            e11FieldInputRadio(
              'inputType',
              'Request that the candidate responds in either a text format or with a video. Their text will be limited to 2,000 characters and their video will be limited to 2 minutes.',
              [
                { name: 'Text', value: QuestionType.Essay },
                { name: 'Video', value: QuestionType.Video },
              ],
              QuestionType.Essay,
              {
                validatorOrOpts: [Validators.required],
              }
            ),
          ],
          {
            fieldsClass: 'e11-w-2/3',
            containerClass: 'e11-w-full e11-mb-4',
          }
        ),
        e11FormLayoutFullWidth(
          [
            e11FieldInputCheckBox(
              'saveToLibrary',
              'Would you like to save this question to the Question Library for future use?',
              [{ name: 'Yes, save to library', value: 'saveToLibrary' }],
              false,
              {
                validatorOrOpts: [Validators.required],
                extraInputOption: {
                  disabled: disableSaveToLibrary,
                  disabledText: 'You cannot save a question you are editing to the library - edit it in the library directly',
                },
                isHidden: this.isSaveToLibraryHidden,
              }
            ),
          ],
          {
            containerClass: 'e11-w-full',
          }
        ),
      ]),
    ])
  }

  handleValueChange(value: IWriteInterviewFormData) {
    if (!isEqual(value, this.writeFormData)) {
      this.writeFormData = value
    }
  }

  private makeInterviewQuestion(user: IEmployerUser): AddInterviewQuestionRequest {
    return {
      id: uuid(),
      question: this.writeFormData.question,
      categories: [],
      tags: [],
      inputType: this.writeFormData.inputType,
      authorId: user.id!,
      authorName: user.displayName!,
      date: new Date(),
      customerKey: user.customerKey,
    }
  }

  private updateExistingQuestion(questionToEdit: IInterviewQuestion) {
    this.onUpdateQuestion.emit({
      type: this.writeFormData.inputType,
      question: this.writeFormData.question,
      id: questionToEdit.id,
    })
  }

  private resetForm() {
    this.questionToEdit = undefined
    this.resetPatch()
  }
}
