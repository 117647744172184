import { CommonModule, DatePipe } from '@angular/common'
import { Component, inject, Input, ViewChild } from '@angular/core'
import { E11TabsModule } from '@engineering11/ui-lib/e11-tabs'
import { TranslateModule } from '@ngx-translate/core'
import { JobProfileStore } from '@employer/app/modules/jobs/stores/job-profile.store'
import { combineLatest, map, Observable } from 'rxjs'
import { PermissionStore } from '@engineering11/access-web'
import { IAutomationFormConfig, systemSenderOption } from '../../../automation-form-message'
import { JobAutomationPermissions } from '../../../automation.constants'
import { ISelectOption } from '@engineering11/ui-lib/e11-select'
import { IJobPostContentAssociation } from '@employer/app/models/job-post.model'
import { LetDirective } from '@ngrx/component'
import { autoQuestionFormDefaults, IAutoQuestionFormData, IAutoQuestionMessageFormData } from '../../auto-questions.model'
import { AutomatedQuestionMessage } from './automated-question-message'

@Component({
  selector: 'automated-question-messages-form',
  standalone: true,
  imports: [E11TabsModule, CommonModule, TranslateModule, DatePipe, AutomatedQuestionMessage, LetDirective],
  template: `
    <div class="e11-block e11-my-4"></div>
    @defer {
    <e11-tabs [tabsClassOverrides]="'e11-justify-start e11-gap-6'">
      <e11-tab [title]="'Initial Message' | translate">
        @defer {
        <ng-container *ngrxLet="formConfig$ as formConfig">
          <automated-question-message
            #initialMessageForm
            [formConfig]="formConfig"
            [showResetButton]="true"
            [defaultValue]="autoQuestionFormDefaults.initialMessage"
            [initialValue]="formData?.initialMessage ?? autoQuestionFormDefaults.initialMessage"
            [title]="formTitles.initialMessage | translate"
            [tooltipText]="formTooltipTexts.initialMessage | translate"
          ></automated-question-message>
        </ng-container>
        }
      </e11-tab>
      <e11-tab [title]="'Follow Up Message (Reminder)' | translate">
        @defer {
        <ng-container *ngrxLet="formConfig$ as formConfig">
          <automated-question-message
            #followUpMessageForm
            [formConfig]="formConfig"
            [showResetButton]="true"
            [defaultValue]="autoQuestionFormDefaults.followUp"
            [initialValue]="formData?.followUp ?? autoQuestionFormDefaults.followUp"
            [title]="formTitles.followUp | translate"
            [tooltipText]="formTooltipTexts.followUp | translate"
          ></automated-question-message>
        </ng-container>
        }
      </e11-tab>
      <e11-tab [title]="'Final Message (Warning)' | translate">
        @defer {
        <ng-container *ngrxLet="formConfig$ as formConfig">
          <automated-question-message
            #finalMessageForm
            [formConfig]="formConfig"
            [showResetButton]="true"
            [defaultValue]="autoQuestionFormDefaults.warning"
            [initialValue]="formData?.warning ?? autoQuestionFormDefaults.warning"
            [title]="formTitles.warning | translate"
            [tooltipText]="formTooltipTexts.warning | translate"
          ></automated-question-message>
        </ng-container>
        }
      </e11-tab>
    </e11-tabs>
    }
  `,
  styles: ``,
})
export class AutomatedQuestionMessagesFormComponent {
  @ViewChild('initialMessageForm') initialMessageForm!: AutomatedQuestionMessage
  @ViewChild('followUpMessageForm') followUpMessageForm!: AutomatedQuestionMessage
  @ViewChild('finalMessageForm') finalMessageForm!: AutomatedQuestionMessage

  systemSenderOption = systemSenderOption
  autoQuestionFormDefaults = autoQuestionFormDefaults

  @Input() formData: IAutoQuestionFormData | undefined

  #jobProfileStore = inject(JobProfileStore)

  permissionStore = inject(PermissionStore)

  canEditMessages$ = this.permissionStore.hasPermission(JobAutomationPermissions.CustomizeMessage)

  senderOptions$: Observable<ISelectOption[]> = this.#jobProfileStore.jobPostHiringTeam$.pipe(map(hiringTeamToSelectOptions))
  formConfig$: Observable<IAutomationFormConfig> = combineLatest([this.senderOptions$, this.canEditMessages$]).pipe(
    map(([senderOptions, canEditMessages]) => ({ senderOptions: senderOptions ?? [systemSenderOption], canEditMessages }))
  )

  constructor() {}

  formTitles = {
    initialMessage: 'The message is sent to the candidate to inform them they have questions expecting answers.',
    followUp: 'The message is sent to the candidate to remind them we asked them questions in a previous message.',
    warning:
      'This message is sent to the candidate as a warning that we have an outstanding request and if it is not handled in a timely manner, the candidate will no longer be considered for the job.',
  }
  formTooltipTexts = {
    initialMessage: 'This will reset the message content to what has been configured globally under your Company Settings.',
    followUp: 'This will reset the message content to what has been configured globally under your Company Settings.',
    warning: 'This will reset the message content to what has been configured globally under your Company Settings.',
  }

  validateAndReturnData(): Partial<IAutoQuestionMessageFormData> | undefined {
    const initialMessage = this.initialMessageForm?.messageData
    const followUp = this.followUpMessageForm?.messageData
    const warning = this.finalMessageForm?.messageData

    const messageData = {
      initialMessage: {
        ...autoQuestionFormDefaults.initialMessage,
        ...this.formData?.initialMessage,
        ...initialMessage,
      },
      followUp: {
        ...autoQuestionFormDefaults.followUp,
        ...this.formData?.followUp,
        ...followUp,
      },
      warning: {
        ...autoQuestionFormDefaults.warning,
        ...this.formData?.warning,
        ...warning,
      },
    }

    return messageData
  }
}

function hiringTeamToSelectOptions(hiringTeam?: Map<string, IJobPostContentAssociation>): ISelectOption[] {
  if (!hiringTeam) return [systemSenderOption]
  const hiringTeamMembers = Array.from(hiringTeam.values()).map(u => ({ name: `${u.firstName} ${u.lastName}`, value: u.userId }))
  return [systemSenderOption].concat(hiringTeamMembers)
}
