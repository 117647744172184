import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { IAutomationFormConfig, sendMessageForm } from '@employer/app/components/jobs-automation/automation-form-message'
import { ISendMessageFormData } from '@employer/app/components/jobs-automation/job-automation.model'
import { AutomationDelayInfoComponent } from '@employer/app/components/jobs-automation/partials/automation-delay-info.component'
import { E11DynamicFormBuilderComponent, E11DynamicFormModule, E11FormForType } from '@engineering11/ui-lib/e11-form-builder'
import { E11InputLabelModule } from '@engineering11/ui-lib/e11-input-label'
import { E11ShowHideControllerComponent, E11ShowHideWrapperComponent } from '@engineering11/ui-lib/e11-show-hide'
import { E11SwitchModule } from '@engineering11/ui-lib/e11-switch'
import { TranslateModule } from '@ngx-translate/core'
import { AutomationResetComponent } from '../../../../components/jobs-automation/partials/automation-reset.component'
import { AutomationUpgradePromptComponent } from '../../../../components/jobs-automation/partials/automation-upgrade-prompt.component'
import { automatedQuestionMessageFormDefaults, IAutomatedQuestionMessageFormData } from './auto-question-message.model'
import { ICompanyAutomationFormData } from './job-post-automation-company.repository'

export interface IAutoQuestionForms {
  autoQuestionInitial: E11FormForType<ISendMessageFormData>
  autoQuestionFollowUp: E11FormForType<ISendMessageFormData>
  autoQuestionFinal: E11FormForType<ISendMessageFormData>
}

@Component({
  selector: 'automation-auto-question-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    E11DynamicFormModule,
    E11InputLabelModule,
    E11SwitchModule,
    E11ShowHideControllerComponent,
    E11ShowHideWrapperComponent,
    AutomationDelayInfoComponent,
    AutomationResetComponent,
    AutomationUpgradePromptComponent,
  ],
  template: `
    <ng-container>
      <div class="e11-flex e11-flex-col e11-gap-2 e11-mb-2">
        <div class="e11-flex e11-gap-2 e11-items-center">
          <h3 class="e11-text-skin-primary-accent">{{ 'Automated Questions' | translate }}</h3>
          <!-- <e11-switch [checked]="formData.enabled" (changes)="formData.enabled = $event" [size]="'xs'"></e11-switch> -->
          <e11-show-hide-controller
            [containerClass]="'e11-text-skin-primary-accent'"
            [showText]="'Show Settings'"
            [hideText]="'Hide Settings'"
            [isCollapsed]="isAutomatedQuestionCollapsed"
            (toggle)="isAutomatedQuestionCollapsed = !isAutomatedQuestionCollapsed"
          >
          </e11-show-hide-controller>
        </div>
      </div>
      <e11-show-hide-wrapper [isCollapsed]="isAutomatedQuestionCollapsed">
        <ng-container>
          <div class="e11-flex e11-gap-2 e11-items-center">
            <h4 class="e11-mb-2 e11-font-bold">{{ 'Initial Message' | translate }}</h4>
            <e11-switch
              [checked]="autoQuestionInitialMessageForm.form?.get('enabled')?.value ?? false"
              (changes)="autoQuestionInitialMessageForm.form?.get('enabled')?.setValue($event)"
              [size]="'xs'"
            ></e11-switch>
          </div>
          <e11-dynamic-form-builder
            #autoQuestionInitialMessageForm
            [loading]="loading"
            [fieldGroups]="form!.autoQuestionInitial"
            [initialValue]="formData.automatedQuestionMessage?.initialMessage"
            [hideSubmitMenu]="true"
            (valueChanges)="onValueChanges('initialMessage', $event)"
          >
            <ng-template e11Template="messageFootNoteTemplate">
              <div class="e11-my-4 ">
                <span>{{ 'Sent to the candidates when they have not answered the questions in a chosen time frame.' | translate }}</span>
              </div>
            </ng-template>
            <ng-template e11Template="delayTimeHeaderTemplate">
              <automation-delay-info></automation-delay-info>
            </ng-template>
            <ng-template e11Template="upgradeProductTemplate">
              <automation-upgrade-prompt></automation-upgrade-prompt>
            </ng-template>
          </e11-dynamic-form-builder>
        </ng-container>
        <ng-container>
          <div class="e11-flex e11-gap-2 e11-items-center">
            <h4 class="e11-mb-2 e11-font-bold">{{ 'Follow up Message' | translate }}</h4>
            <e11-switch
              [checked]="autoQuestionFollowUpMessageForm.form?.get('enabled')?.value ?? false"
              (changes)="autoQuestionFollowUpMessageForm.form?.get('enabled')?.setValue($event)"
              [size]="'xs'"
            ></e11-switch>
          </div>
          <e11-dynamic-form-builder
            #autoQuestionFollowUpMessageForm
            [loading]="loading"
            [fieldGroups]="form!.autoQuestionFollowUp"
            [initialValue]="formData.automatedQuestionMessage?.followUp"
            [hideSubmitMenu]="true"
            (valueChanges)="onValueChanges('followUp', $event)"
          >
            <ng-template e11Template="messageFootNoteTemplate">
              <div class="e11-my-4 ">
                <span>{{ 'Sent to the candidates when they have not answered the questions in a chosen time frame.' | translate }}</span>
              </div>
            </ng-template>
            <ng-template e11Template="delayTimeHeaderTemplate">
              <automation-delay-info></automation-delay-info>
            </ng-template>
            <ng-template e11Template="upgradeProductTemplate">
              <automation-upgrade-prompt></automation-upgrade-prompt>
            </ng-template>
          </e11-dynamic-form-builder>
        </ng-container>
        <ng-container>
          <div class="e11-flex e11-gap-2 e11-items-center">
            <h4 class="e11-mb-2 e11-font-bold">{{ 'Final Message' | translate }}</h4>
            <e11-switch
              [checked]="autoQuestionFinalMessageForm.form?.get('enabled')?.value ?? false"
              (changes)="autoQuestionFinalMessageForm.form?.get('enabled')?.setValue($event)"
              [size]="'xs'"
            ></e11-switch>
          </div>
          <e11-dynamic-form-builder
            #autoQuestionFinalMessageForm
            [loading]="loading"
            [fieldGroups]="form!.autoQuestionFinal"
            [initialValue]="formData.automatedQuestionMessage?.final"
            [hideSubmitMenu]="true"
            (valueChanges)="onValueChanges('final', $event)"
          >
            <ng-template e11Template="messageFootNoteTemplate">
              <div class="e11-my-4 ">
                <span>{{ 'Sent to the candidates when they have not responded to previous messages, questions or requests.' | translate }}</span>
              </div>
            </ng-template>
            <ng-template e11Template="delayTimeHeaderTemplate">
              <automation-delay-info></automation-delay-info>
            </ng-template>
            <ng-template e11Template="upgradeProductTemplate">
              <automation-upgrade-prompt></automation-upgrade-prompt>
            </ng-template>
          </e11-dynamic-form-builder>
        </ng-container>
      </e11-show-hide-wrapper>
    </ng-container>
  `,
})
export class AutoQuestionFormComponent implements OnInit {
  @ViewChild('autoQuestionInitialMessageForm') autoQuestionInitialMessageForm!: E11DynamicFormBuilderComponent<ISendMessageFormData>
  @ViewChild('autoQuestionFollowUpMessageForm') autoQuestionFollowUpMessageForm!: E11DynamicFormBuilderComponent<ISendMessageFormData>
  @ViewChild('autoQuestionFinalMessageForm') autoQuestionFinalMessageForm!: E11DynamicFormBuilderComponent<ISendMessageFormData>
  @Input() loading: boolean = false
  @Input() formData!: ICompanyAutomationFormData
  @Output() valueChanges = new EventEmitter<IAutomatedQuestionMessageFormData>()
  form!: IAutoQuestionForms
  _formConfig?: IAutomationFormConfig
  @Input() set formConfig(config: IAutomationFormConfig) {
    this._formConfig = config

    this.form = this.buildForm(config)
  }

  isAutomatedQuestionCollapsed = false

  autoQuestionMessage: IAutomatedQuestionMessageFormData | undefined = undefined

  ngOnInit() {
    this.autoQuestionMessage = this.formData.automatedQuestionMessage
      ? { ...this.formData.automatedQuestionMessage }
      : { ...automatedQuestionMessageFormDefaults }
  }

  onValueChanges(key: keyof IAutomatedQuestionMessageFormData, value: ISendMessageFormData) {
    if (!this.autoQuestionMessage) {
      return
    }
    this.autoQuestionMessage[key] = value
    this.valueChanges.emit({ ...this.autoQuestionMessage })
  }

  private buildForm(config: IAutomationFormConfig) {
    return {
      autoQuestionInitial: sendMessageForm(automatedQuestionMessageFormDefaults.initialMessage, config),
      autoQuestionFollowUp: sendMessageForm(automatedQuestionMessageFormDefaults.followUp, config),
      autoQuestionFinal: sendMessageForm(automatedQuestionMessageFormDefaults.final, config),
    }
  }
}
