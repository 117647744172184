export const WHAT_TO_DO = [
  {
    icon: 'more_time',
    title: 'Choose when to ask',
    description:
      'Pick at which point in the process your question will be sent to the candidates. This can be set to when the application is moved to a specific status / swimlane.',
  },
  {
    icon: 'help_center',
    title: 'Pick / Write your questions',
    description: 'Attach individual questions or pick sets of predetermined questions customized to fit the needs of a specific job post.',
  },
  {
    icon: 'edit',
    title: 'Write your messages',
    description:
      'Write the message(s) that will be sent to the candidate, letting them know they have questions from you. Additionally, write a follow-up reminder message.',
  },
]

export const WHAT_HAPPENS = [
  {
    icon: 'settings_suggest',
    title: 'Application processed',
    description: 'The system recognizes when the application has been placed in the status or swimlane that automatically sends out questions.',
  },
  {
    icon: 'message',
    title: 'Message sent',
    description: 'The candidate receives your message, letting them know they now have the questions you configured.',
  },
  {
    icon: 'task_alt',
    title: 'Hiring team',
    description: 'The hiring team sees that questions have been sent and can effortlessly and effectively evaluate the candidate.',
  },
]
